import { useCallback, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';

function useHtmlParser() {
  const parseHtml = useCallback((text: string) => {
    return ReactHtmlParser(text);
  }, []);
  const ctx = useMemo(() => ({ parseHtml }), [parseHtml]);
  return ctx;
}

export default useHtmlParser;
