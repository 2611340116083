import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotify } from './useNotify';

export interface IUseOnMutationFinishNotificationsResult {
  success: (operation: string, flat?: boolean) => void;
  fail: (operation: string, flat?: boolean) => void;
}

export const useOnMutationFinishNotifications = (): IUseOnMutationFinishNotificationsResult => {
  const { t } = useTranslation();
  const notify = useNotify();

  const handleFail = useCallback(
    (operation: string, flat?: boolean) => {
      const message = t(`${operation}${flat ? '' : '_fail'}`);
      notify(message, { type: 'error' });
    },
    [notify, t]
  );

  const handleSuccess = useCallback(
    (operation: string, flat?: boolean) => {
      const message = t(`${operation}${flat ? '' : '_success'}`);
      notify(message, { type: 'success' });
    },
    [notify, t]
  );

  const ctx = useMemo(
    () => ({
      fail: handleFail,
      success: handleSuccess,
    }),
    [handleFail, handleSuccess]
  );

  return ctx;
};
