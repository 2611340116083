import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TeamContext } from '../../contexts/contexts';
import { UserTeamMemberProfileFragment } from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { DropdownItem, DropdownItemButton } from '../Common/Common';
import ProfileHeader from '../ProfileHeader/ProfileHeader';

interface IProps {}

const HeaderUserDropdownMenu: React.FC<IProps> = props => {
  const { t } = useTranslation();

  const { profile, logout } = useContext(AuthContext);
  const { switchTeam, teamMembersip, team } = useContext(TeamContext);
  const history = useHistory();
  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleGoToBilling = useCallback(() => {
    history.push('/transactions');
  }, [history]);

  const renderHeader = useCallback(() => profile && <ProfileHeader editable size="small" user={profile} />, [profile]);

  const renderTeamItem = useCallback(
    ({ team: item }: UserTeamMemberProfileFragment) => {
      const isActive = item.id === team?.id;
      const handleClick = (evt: React.SyntheticEvent) => {
        evt?.stopPropagation();
        if (isActive) {
          return;
        }
        switchTeam(item);
      };

      return (
        <DropdownItemButton key={item.id} $isActive={isActive} onClickCapture={handleClick}>
          <div>{item.name ? item.name : `${item.owner.firstName} ${item.owner.lastName}`}</div>
        </DropdownItemButton>
      );
    },
    [switchTeam, team?.id]
  );

  const renderBody = useCallback(
    () => <div>{teamMembersip?.map(renderTeamItem)}</div>,
    [renderTeamItem, teamMembersip]
  );

  const renderFooter = useCallback(
    () =>
      profile && (
        <DropdownItemButton className="d-flex justify-content-center" onClick={handleLogout}>
          {t('a_sign_out')}
        </DropdownItemButton>
      ),
    [handleLogout, profile, t]
  );

  const renderTransactionNavButton = useCallback(
    () =>
      profile && (
        <DropdownItemButton className="d-flex" onClick={handleGoToBilling}>
          <div>{t('a_go_to_billing')}</div>
        </DropdownItemButton>
      ),
    [handleGoToBilling, profile, t]
  );

  return (
    <div className="d-flex flex-column flex-grow-1 py-2">
      <div className="px-1">{renderHeader()}</div>
      <div className="px-1">
        <div className="mt-3">{renderBody()}</div>
        <DropdownItem divider />
        {renderTransactionNavButton()}
        <DropdownItem divider />
        {renderFooter()}
      </div>
    </div>
  );
};

export default React.memo(HeaderUserDropdownMenu);
