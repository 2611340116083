import React, { useCallback } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { ILayoutLink } from '../../hooks/useLayoutLinks';
import { APPSTORE_URL, SUPPORT_EMAIL, SUPPORT_EMAIL_URL } from '../../lib/constants';
import { GET_COLOR_CONTRAST_2 } from '../../lib/themeGetters';
import Col from '../Col/Col';
import Container from '../Container/Container';
import IosIcon from '../IosIcon/IosIcon';
import LangSwitch from '../LangSwitch/LangSwitch';
import Logo from '../Logo/Logo';
import Row from '../Row/Row';

interface IProps {
  links: ILayoutLink[];
}

const StyledFooter = styled.footer`
  margin-top: auto;
  border-top: solid;
  border-top-color: ${GET_COLOR_CONTRAST_2};
  border-top-width: 0.5px;
  margin-right: -15px;
`;

const StyledRow = styled(Row)`
  height: 100%;
  margin-right: 15px;
`;

const TrademarkContainer = styled.span``;

const Footer: React.FC<IProps> = props => {
  const links = props.links;
  const handleScroll = useCallback((el: HTMLElement) => el.scrollIntoView({ behavior: 'auto', block: 'center' }), []);
  const renderLink = useCallback(
    (item: ILayoutLink, index: number) => {
      return (
        <Col xs={6} className={`d-none d-sm-flex ${index !== 0 && index !== 1 ? 'mt-3' : ''}`} key={index}>
          <NavHashLink scroll={handleScroll} to={item.link}>
            {item.text}
          </NavHashLink>
        </Col>
      );
    },
    [handleScroll]
  );

  const renderMobileLink = useCallback(
    (item: ILayoutLink, index: number) => {
      return (
        <Col xs={12} className={`d-flex d-sm-none pl-0 mt-2`} key={index}>
          <NavHashLink scroll={handleScroll} to={item.link}>
            {item.text}
          </NavHashLink>
        </Col>
      );
    },
    [handleScroll]
  );

  const renderLinks = useCallback(() => {
    return (
      <div className="d-flex justify-content-between flex-wrap flex-grow-1">
        {links.map(renderLink)}
        {links.map(renderMobileLink)}
      </div>
    );
  }, [links, renderLink, renderMobileLink]);

  const renderLeftSide = useCallback(() => {
    return (
      <div className="d-flex justify-content-center flex-column">
        <Logo />
        <TrademarkContainer>© 2022 Publisher</TrademarkContainer>
      </div>
    );
  }, []);

  const renderRightSide = useCallback(() => {
    return (
      <div className="d-flex justify-content-center align-items-end flex-column">
        <a href={APPSTORE_URL} target={'_blank'}><IosIcon /></a>
        <a className="mt-4" href={SUPPORT_EMAIL_URL}>
          {SUPPORT_EMAIL}
        </a>
      </div>
    );
  }, []);

  const renderLangSwitch = useCallback(() => <LangSwitch />, []);

  return (
    <StyledFooter>
      <Container className="mb-5 mt-4">
        <StyledRow className="d-flex">
          <Col xs={5} sm={5} md={3} lg={2} className="d-flex align-items-center">
            {renderLeftSide()}
          </Col>
          <Col md={5} xs={12} sm={7} lg={4} className="d-flex align-items-center mt-4">
            {renderLinks()}
          </Col>
          <Col xs={3} lg={3} className="mt-4">
            {renderLangSwitch()}
          </Col>
          <Col xs={6} md={3} lg={{ size: 3 }} className="d-none d-md-flex justify-content-end align-items-center mt-4">
            {renderRightSide()}
          </Col>
          <Col xs={12} className="d-flex d-md-none justify-content-start align-items-center mt-4">
            {renderRightSide()}
          </Col>
        </StyledRow>
      </Container>
    </StyledFooter>
  );
};

export default React.memo(Footer);
