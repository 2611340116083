import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useLayoutLinks from '../../hooks/useLayoutLinks';
import { GET_COLOR_MAIN_1 } from '../../lib/themeGetters';
import Col from '../Col/Col';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import HeaderActions from '../HeaderActions/HeaderActions';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import NavBurger from '../NavBurger/NavBurger';

interface IProps {}

const LayoutWrapper = styled.div`
  background-color: ${GET_COLOR_MAIN_1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const PublicLayout: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { getLinks } = useLayoutLinks('public');

  const location = useLocation();
  const openRegisterModal = location.hash === '#register'
  const openLoginModal = location.hash === '#login'

  const renderActions = useCallback(
    () => (
      <Col
        noGutters
        className="d-none d-xs-none d-sm-none d-md-flex"
        xs={{ size: 4, offset: 0 }}
        sm={{ size: 6, offset: 0 }}
        md={{ size: 5, offset: 4 }}
        lg={{ size: 4, offset: 4 }}
        xl={{ size: 3, offset: 0 }}
      >
        <HeaderActions openRegisterModal={openRegisterModal} openLoginModal={openLoginModal} />
      </Col>
    ),
    [openRegisterModal, openLoginModal]
  );

  const renderBurgerActions = useCallback(
    () => (
      <div className="d-sm-flex d-md-none">
        <HeaderActions vertical />
      </div>
    ),
    []
  );
  const headerLinks = useMemo(() => getLinks('header'), [getLinks]);
  const renderMenu = useCallback(() => <HeaderMenu links={headerLinks} />, [headerLinks]);
  const footerLinks = useMemo(() => getLinks('footer'), [getLinks]);

  const renderHeaderMenus = useCallback(() => {
    return (
      <>
        <Col className="d-none d-xl-flex align-items-center" sm={10} xs={6} md={6} lg={7} xl={7}>
          {renderMenu()}
        </Col>
        {renderActions()}
        <Col xs={6} md={1} sm={6} lg={2} className="d-flex d-xl-none align-items-center justify-content-end">
          <NavBurger renderActions={renderBurgerActions} links={headerLinks} />
        </Col>
      </>
    );
  }, [headerLinks, renderActions, renderBurgerActions, renderMenu]);

  return (
    <LayoutWrapper>
      <Header renderHeaderMenus={renderHeaderMenus} renderActions={renderActions} renderMenu={renderMenu} />
      <div>{props.children}</div>
      <Footer links={footerLinks} />
    </LayoutWrapper>
  );
};

export default React.memo(PublicLayout);
