import React from 'react';

interface IProps {}

const OkIcon: React.FC<IProps> = props => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#60E7B6"
      />
      <path d="M25 10L13 22L7 16" stroke="white" strokeWidth="2" strokeLinecap="square" />
    </svg>
  );
};

export default React.memo(OkIcon);
