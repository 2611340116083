import { ApolloError, FetchResult } from '@apollo/client';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import { AuthContext } from '../components/AuthProvider/AuthProvider';
import { PRIVATE_ENTRY_PAGE } from '../lib/constants';
import { RegisterMutation, useRegisterMutation } from '../types/graphql';
import { TUseFormikResult } from '../types/local';
import useModal from './useModal';

export type TUseRegisterUserFormResult = {
  formik: TUseFormikResult<TRegisterFormikFields>;
  error?: ApolloError | null;
  isLoading: boolean;
  isRegisterDone: boolean;
  closeSuccessModal: () => void;
  onSubmit: () => void;
  handleRegister: () => void;
  closeModal: () => void;
  isModalOpened: boolean;
};

export type TRegisterFormikFields = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

function useRegisterUserForm(isInitialOpen?: boolean): TUseRegisterUserFormResult {
  const { isModalOpened, closeModal, openModal } = useModal();
  const { t } = useTranslation();
  const requiredText = t('e_required');
  const wrongEmailText = t('e_wrong_email');
  const [registerUser, registerUserResult] = useRegisterMutation();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (isInitialOpen) {
      openModal();
    }
  }, [isInitialOpen, openModal]);

  const formik = useFormik<TRegisterFormikFields>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(wrongEmailText).required(requiredText),
      firstName: Yup.string().required(requiredText),
      lastName: Yup.string().required(requiredText),
      password: Yup.string().required(requiredText),
    }),

    onSubmit: values => {
      const data = values;
      console.log('DATA: ', data);
      handleSendData(data);
    },
  });

  const handleSuccess = useCallback(
    (response: FetchResult<RegisterMutation>) => {
      const userAuthResponse = response?.data?.register;
      if (!userAuthResponse) {
        throw new Error(t('auth_error'));
      }

      authContext.login(userAuthResponse, () => {
        history.push(PRIVATE_ENTRY_PAGE);
      });

      closeModal();
      formik.resetForm();
    },
    [authContext, closeModal, formik, t, history]
  );

  const handleFail = useCallback(() => {}, []);

  const handleSendData = useCallback(
    data => {
      registerUser({ variables: { data } }).then(handleSuccess).catch(handleFail);
    },
    [handleFail, handleSuccess, registerUser]
  );

  const isLoading = registerUserResult.loading;
  const error = registerUserResult.error;

  const onSubmit = formik.handleSubmit;

  const handleRegister = useCallback(() => {
    if (authContext.profile) {
      history.push(PRIVATE_ENTRY_PAGE);
      return;
    }
    openModal();
  }, [authContext.profile, history, openModal]);

  const ctx = useMemo(
    () => ({
      onSubmit,
      formik,
      isLoading,
      error,
      closeSuccessModal: closeModal,
      isRegisterDone: isModalOpened,
      handleRegister,
      closeModal,
      isModalOpened,
    }),
    [closeModal, error, formik, handleRegister, isLoading, isModalOpened, onSubmit]
  );
  return ctx;
}

export default useRegisterUserForm;
