import { DefaultTheme } from 'styled-components';
import { COMMON_THEME_FIELDS, RANDOM_COLORS } from './assets/common';

export const THEME_BLACK: DefaultTheme = {
  name: 'black',
  randomColors: RANDOM_COLORS,
  colors: {
    main1: '#01091A',
    main2: '#061043',
    main3: '#061043',

    contrast1: '#FFFFFF',
    contrast2: '#c6c6c6',
    contrast3: '#434343',
    contrast4: '#a0a0a0',

    contrastVibrant1: '#FFFFFF',
    contrastVibrant2: '#FFFFFF',
    contrastVibrant3: '#FFFFFF',

    info1: '#77A2E4',
    info2: '#71C3E0',
    info3: '#6BD9DA',

    danger1: '#DB0815',
    danger2: '#E65F5E',
    danger3: '#D91521',
    danger4: '#FF003D',

    success1: '#7AE994',
    success2: '#7AE994',
    success3: '#7AE994',

    warning1: '#EC9460',
    warning2: '#EC9460',
    warning3: '#EC9460',

    primary1: '#FE0000',
    primary2: '#FFE0E0',
    primary3: '#3ED79B',

    secondary1: '#FFFFFF',
    secondary2: 'rgba(255,255,255, 0.26)',
    secondary3: '#628f9d',
    //secondary3: '#6BD9DA',

    parkingWidgetBackground: '#287CE2',
  },
  shadows: {
    shadow1: `1px 2px 5px 0 rgb(163 163 163)`,
    shadow2: `4px 4px 10px 0 rgba(255, 255, 255, 1)`,
    shadow3: `0px 5px 10px 0 rgba(255, 255, 255, 0.1)`,
    danger1: `0px 2px 3px 0 rgba(243, 32, 19, 0.1)`,
  },
  separator: {
    colors: {
      main1: '#1C3483',
    },
  },
  background: {
    parkingTicket: 'rgba(0,0,0,0.29)',
    matchTicket: 'rgba(0,0,0,0.33)',
  },
  gradients: {},
  lodgeColor: {
    active: '#90192B',
    sectorSale: '#84744A',
    inactive: '#DCDCDC',
  },
  ...COMMON_THEME_FIELDS,
};
