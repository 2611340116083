import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TUseRegisterUserFormResult } from '../../hooks/useRegisterUserForm';
import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldSet from '../FieldSet/FieldSet';
import FormBottomContainer from '../FormBottomContainer/FormBottomContainer';
import Input from '../Input/Input';

interface IProps {
  form: TUseRegisterUserFormResult;
  showLoginModal?: () => void; // only used on landing page for special case
}

const RegisterForm: React.FC<IProps> = props => {
  const { showLoginModal } = props;
  const { isLoading, error, formik } = props.form;
  const { t } = useTranslation();

  const registerText = t('a_register');
  const emailHelp = t('d_email');
  const firstNameHelp = t('d_firstName');
  const lastNameHelp = t('d_lastName');
  const passwordHelp = t('d_register_password');

  const handleBackToLogin = useCallback(() => {
    props.form.closeModal();
    showLoginModal && showLoginModal();
  }, [props.form, showLoginModal]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <FieldSet
          touched={formik.touched.email}
          error={formik.errors.email}
          id="email"
          label={t('email')}
          help={emailHelp}
        >
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FieldSet>
        <FieldSet
          help={firstNameHelp}
          touched={formik.touched.firstName}
          error={formik.errors.firstName}
          id="firstName"
          label={t('firstName')}
        >
          <Input
            autoComplete="firstName"
            id="firstName"
            name="firstName"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
        </FieldSet>
        <FieldSet
          help={lastNameHelp}
          touched={formik.touched.lastName}
          error={formik.errors.lastName}
          id="lastName"
          label={t('lastName')}
        >
          <Input
            autoComplete="lastName"
            id="lastName"
            name="lastName"
            type="text"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
        </FieldSet>
        <FieldSet
          help={passwordHelp}
          touched={formik.touched.password}
          error={formik.errors.password}
          id="password"
          label={t('password')}
        >
          <Input
            autoComplete="password"
            name="password"
            type="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </FieldSet>

        <FormBottomContainer className="d-flex justify-content-between">

          <Button
            onClick={handleBackToLogin}
            style={{ marginLeft: '5px' }}
            //type="submit"
            disabled={false}
            isLoading={false}
            color="secondary"
            visibility="medium"
          >
            {t('a_back_to_login')}
          </Button>
          <Button type="submit" disabled={!formik.isValid} isLoading={isLoading} color="secondary" visibility="strong">
            {registerText}
          </Button>
        </FormBottomContainer>

        <ErrorMessage simple className="mt-2" error={error} />
      </form>
    </>
  );
};

export default React.memo(RegisterForm);
