import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { TUseForgotPasswordForm } from '../../hooks/useForgotPasswordForm';
import Button from '../Button/Button';
import Desctiption from '../Desctiption/Desctiption';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldSet from '../FieldSet/FieldSet';
import FormBottomContainer from '../FormBottomContainer/FormBottomContainer';
import Input from '../Input/Input';
import Title from '../Title/Title';

interface IProps {
  form: TUseForgotPasswordForm;
}

const ForgotPasswordForm: React.FC<IProps> = props => {
  const { isLoading, error, onSubmit, timer } = props.form;

  const time = timer.time;

  const buttonDisabled = typeof time === 'number' ? time !== 0 : false;

  const { t } = useTranslation();

  const requiredText = t('e_required');
  const wrongEmailText = t('e_wrong_email');
  const submitText = t('a_restore_password');
  const emailHelp = t('d_restore_password_email');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(wrongEmailText).required(requiredText),
    }),

    onSubmit: values => {
      onSubmit(values);
    },
  });

  return (
    <>
      <Title>{t('h_restore_password')}</Title>
      <Desctiption className="mt-4">{t('d_restore_password')}</Desctiption>
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <FieldSet
          touched={formik.touched.email}
          error={formik.errors.email}
          id="email"
          label={t('email')}
          help={emailHelp}
        >
          <Input
            id="email"
            name="email"
            type="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FieldSet>
        <FormBottomContainer className="d-flex justify-content-end">
          <Button type="submit" disabled={buttonDisabled} isLoading={isLoading} color="secondary" visibility="strong">
            {time ? time : ''} {submitText}
          </Button>
        </FormBottomContainer>
        <ErrorMessage simple className="mt-2" error={error} />
      </form>
    </>
  );
};

export default React.memo(ForgotPasswordForm);
