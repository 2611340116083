import { useApolloClient } from '@apollo/client';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TeamContext } from '../../contexts/contexts';
import { getFromLocalStorage, writeToLocalStorage } from '../../lib/localStorage';
import { Maybe, UserTeamFragment } from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';

interface IProps {}

const getSelectedTeamId = () => {
  return getFromLocalStorage('teamId');
};

const setSelectedTeamId = (teamId: number) => {
  return writeToLocalStorage('teamId', teamId);
};

const includesAdd = (accesses?: Maybe<string[]>) => (accesses ? accesses.includes('ADD') : false);
const includesDelete = (accesses?: Maybe<string[]>) => (accesses ? accesses.includes('DELETE') : false);

const TeamProvider: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { profile } = useContext(AuthContext);

  const client = useApolloClient();
  const history = useHistory();

  const teamMembersip = useMemo(() => profile?.teamMembership || [], [profile?.teamMembership]);

  const switchTeam = useCallback(
    (team: UserTeamFragment) => {
      history.push('/users');

      setSelectedTeamId(team.id);
      setStorageTeamId(team.id);
      client.resetStore();
    },
    [client, history]
  );

  const [storagedTeamId, setStorageTeamId] = useState(getSelectedTeamId());

  const currentTeam = useMemo(() => {
    const storagedTeam = teamMembersip.find(teamMembersip => teamMembersip.team.id === storagedTeamId);
    const fallbackTeam = teamMembersip?.[0]?.team;
    return storagedTeam?.team || fallbackTeam;
  }, [storagedTeamId, teamMembersip]);

  const teamMember = useMemo(() => {
    const storagedTeamMember = teamMembersip.find(teamMembersip => teamMembersip.team.id === storagedTeamId);
    const fallbackTeamMember = teamMembersip?.[0];
    return storagedTeamMember || fallbackTeamMember;
  }, [teamMembersip, storagedTeamId]);

  const teamOwner = useMemo(() => currentTeam?.owner, [currentTeam?.owner]);

  const isMeTeamOwner = useMemo(() => profile?.id === currentTeam?.ownerId, [currentTeam?.ownerId, profile?.id]);

  const isMeAdmin = !!teamMember?.role?.isAdmin;

  const canInviteUsers = useMemo(() => includesAdd(teamMember?.role?.userManagment), [teamMember?.role?.userManagment]);
  const canDeleteUsers = useMemo(
    () => includesDelete(teamMember?.role?.userManagment),
    [teamMember?.role?.userManagment]
  );

  const canCreateLibraryItem = useMemo(
    () => includesAdd(teamMember?.role?.libraryManagement),
    [teamMember?.role?.libraryManagement]
  );
  const canDeleteLibraryItem = useMemo(
    () => includesDelete(teamMember?.role?.libraryManagement),
    [teamMember?.role?.libraryManagement]
  );
  const canEditLibraryItem = useMemo(() => canCreateLibraryItem, [canCreateLibraryItem]);

  const canCreateTemplate = useMemo(
    () => includesAdd(teamMember?.role?.templatesManagement),
    [teamMember?.role?.templatesManagement]
  );

  const canEditTemplate = useMemo(() => canCreateTemplate, [canCreateTemplate]);

  const canDeleteTemplate = useMemo(
    () => includesDelete(teamMember?.role?.templatesManagement),
    [teamMember?.role?.templatesManagement]
  );

  const canCreateTemplateCategory = useMemo(() => canCreateTemplate, [canCreateTemplate]);

  const canEditTemplateCategory = useMemo(() => canEditTemplate, [canEditTemplate]);

  const canDeleteTemplateCategory = useMemo(() => canDeleteTemplate, [canDeleteTemplate]);

  const canEditLibraryCategory = useMemo(() => canCreateLibraryItem, [canCreateLibraryItem]);
  const canCreateLibraryCategory = useMemo(() => canCreateLibraryItem, [canCreateLibraryItem]);

  const ctx = useMemo(
    () => ({
      team: currentTeam,
      switchTeam,
      teamMembersip,
      teamMember,
      teamOwner,
      isMeTeamOwner,
      isMeAdmin,
      canInviteUsers,
      canDeleteUsers,
      canDeleteLibraryItem,
      canCreateLibraryItem,
      canCreateTemplate,
      canDeleteTemplate,
      canEditLibraryCategory,
      canCreateLibraryCategory,
      canEditLibraryItem,
      canEditTemplate,
      canCreateTemplateCategory,
      canEditTemplateCategory,
      canDeleteTemplateCategory,
    }),
    [
      currentTeam,
      switchTeam,
      teamMembersip,
      teamMember,
      teamOwner,
      isMeTeamOwner,
      isMeAdmin,
      canInviteUsers,
      canDeleteUsers,
      canDeleteLibraryItem,
      canCreateLibraryItem,
      canCreateTemplate,
      canDeleteTemplate,
      canEditLibraryCategory,
      canCreateLibraryCategory,
      canEditLibraryItem,
      canEditTemplate,
      canCreateTemplateCategory,
      canEditTemplateCategory,
      canDeleteTemplateCategory,
    ]
  );

  return <TeamContext.Provider value={ctx}>{props.children}</TeamContext.Provider>;
};

export default React.memo(TeamProvider);
