import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { getAuthMiddleware, getErrorMiddleware, getHttpMiddleware } from './configureMiddlewares';
import { getFromLocalStorage } from './localStorage';

type TOptions = { lang?: string };

export function getMiddleWare(options?: TOptions) {
  const storedLocale = getFromLocalStorage('locale');
  const lang = options?.lang || storedLocale || undefined;
  const authLink = getAuthMiddleware(lang);
  const httpLink = getHttpMiddleware();
  const errorLink = getErrorMiddleware();

  // Combine links
  return ApolloLink.from([errorLink, authLink, httpLink]);
}

export function configureApollo(): ApolloClient<any> {
  const cache = new InMemoryCache({
    // typePolicies: {
    //   Query: {
    //     fields: {
    //       allLibraryBackgrounds: {
    //         read(existing, { variables }) {
    //           const query = variables?.query;
    //           const filters = query?.filters;
    //           console.log('filters', filters);
    //           const cursor = query?.cursor || 0;
    //           const limit = query?.limit || BASE_LIMIT;
    //           // A read function should always return undefined if existing is
    //           // undefined. Returning undefined signals that the field is
    //           // missing from the cache, which instructs Apollo Client to
    //           // fetch its value from your GraphQL server.
    //           const oldExisting = { ...existing };
    //           const preparedArray = oldExisting?.edges && oldExisting.edges.slice(cursor, cursor + limit);
    //           console.log('preparedArray', preparedArray ? { ...oldExisting, edges: preparedArray } : null);
    //           return preparedArray ? { ...oldExisting, edges: preparedArray } : undefined;
    //         },
    //         The keyArgs list and merge function are the same as above.
    //         keyArgs: [],
    //         merge(existing, incoming, { variables }) {
    //           const query = variables?.query;
    //           const cursor = query?.cursor || 0;
    //           const limit = query?.limit || BASE_LIMIT;
    //           const oldExisting = { ...existing };
    //           console.log('existing', existing);
    //           const merged = oldExisting?.edges ? oldExisting.edges.slice(0) : [];
    //           for (let i = 0; i < incoming.edges.length; ++i) {
    //             merged[cursor + i] = incoming.edges[i];
    //           }
    //           const totalResult = { ...oldExisting, ...incoming, edges: merged };
    //           console.log('totalResult merge', totalResult);
    //           return totalResult;
    //         },
    //       },
    //     },
    //   },
    // },
  });
  const link = getMiddleWare();

  // And construct ApolloClient
  return new ApolloClient({
    link: link,
    cache: cache,
  });
}
