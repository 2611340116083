import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface ILayoutLink {
  text: string;
  link: string;
}

type TComponent = 'footer' | 'header';

export interface IUseLayoutLinksResult {
  getLinks: (component?: TComponent) => ILayoutLink[];
}

function useLayoutLinks(type?: 'private' | 'public') {
  const { t } = useTranslation();

  const headerPrivateLinks = useMemo(
    () => [
      {
        text: t('templates'),
        link: '/templates',
      },
      {
        text: t('library'),
        link: '/library',
      },
      {
        text: t('users'),
        link: '/users',
      },
    ],
    [t]
  );

  const footerPrivateLinks = useMemo(
    () => [
      {
        text: t('templates'),
        link: '/templates',
      },
      {
        text: t('privacy_policy'),
        link: '/legal/privacy-policy/en',
      },
      {
        text: t('library'),
        link: '/library',
      },
      {
        text: t('terms_of_usage'),
        link: '/legal/terms-of-use/en',
      },
      {
        text: t('users'),
        link: '/users',
      },
    ],
    [t]
  );

  const footerPublicLinks = useMemo(
    () => [
      {
        text: t('route_about'),
        link: '#about',
      },
      {
        text: t('route_apps'),
        link: '#apps',
      },
      {
        text: t('route_how_it_works'),
        link: '#how-it-works',
      },
      {
        text: t('route_reviews'),
        link: '#reviews',
      },
      {
        text: t('route_for_whom'),
        link: '#for-whom',
      },

      {
        text: t('route_clients'),
        link: '#clients',
      },
    ],
    [t]
  );

  const headerPublicLinks = useMemo(
    () => [
      {
        text: t('route_about'),
        link: '#about',
      },
      {
        text: t('route_how_it_works'),
        link: '#how-it-works',
      },
      {
        text: t('route_for_whom'),
        link: '#for-whom',
      },
      {
        text: t('route_apps'),
        link: '#apps',
      },
      {
        text: t('route_reviews'),
        link: '#reviews',
      },
    ],
    [t]
  );

  const getLinks = useCallback(
    (component: TComponent) => {
      if (type === 'private') {
        if (component === 'footer') {
          return footerPrivateLinks;
        }
        return headerPrivateLinks;
      }
      if (component === 'footer') {
        return footerPublicLinks;
      }
      return headerPublicLinks;
    },
    [footerPrivateLinks, footerPublicLinks, headerPrivateLinks, headerPublicLinks, type]
  );

  const ctx = useMemo(
    () => ({
      getLinks,
    }),
    [getLinks]
  );

  return ctx;
}

export default useLayoutLinks;
