import React from 'react';
import styled from 'styled-components';
import { GET_COLOR_CONTRAST_2, GET_COLOR_MAIN_1 } from '../../lib/themeGetters';
import Col from '../Col/Col';
import Container from '../Container/Container';
import Logo from '../Logo/Logo';
import Row from '../Row/Row';

interface IProps {
  renderMenu?: () => JSX.Element;
  renderActions?: () => JSX.Element;
  renderBurger?: () => JSX.Element;
  renderHeaderMenus?: () => JSX.Element;
}

const Wrapper = styled.header`
  min-height: 7.5rem;
  margin-right: -15px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${GET_COLOR_MAIN_1};
  border-bottom: solid;
  border-bottom-color: ${GET_COLOR_CONTRAST_2};
  border-bottom-width: 0.5px;
`;

const StyledContainer = styled(Container)``;

const StyledRow = styled(Row)`
  height: 100%;
  margin-right: 15px;
`;

const Header: React.FC<IProps> = props => {
  const { renderMenu, renderActions, renderBurger, renderHeaderMenus } = props;
  return (
    <Wrapper>
      <StyledContainer>
        <StyledRow>
          <Col xs={6} sm={6} md={2} lg={2} className="align-items-center d-flex">
            <Logo />
          </Col>
          {renderHeaderMenus ? (
            renderHeaderMenus()
          ) : (
            <>
              <Col className="d-none d-lg-flex align-items-center" sm={4} xs={4} md={7} lg={8} xl={8}>
                {renderMenu?.()}
              </Col>
              <Col className="d-flex" xs={4} sm={4} md={8} lg={2} xl={2}>
                {renderActions?.()}
              </Col>
              <Col xs={2} sm={2} md={2} className="d-flex d-lg-none align-items-center justify-content-end">
                {renderBurger?.()}
              </Col>
            </>
          )}
        </StyledRow>
      </StyledContainer>
    </Wrapper>
  );
};

export default React.memo(Header);
