import React from 'react';
import styled from 'styled-components';
import Col from '../Col/Col';
import HeaderUser from '../HeaderUser/HeaderUser';

const StyledCol = styled(Col)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 0;
`;

interface IProps {}

const PrivateHeaderActions: React.FC<IProps> = () => {
  return (
    <StyledCol xs={12} md={12} lg={12}>
      <HeaderUser />
    </StyledCol>
  );
};

export default React.memo(PrivateHeaderActions);
