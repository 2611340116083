import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem as StrapDropdownItem, DropdownMenu as StrapDropdownMenu, Modal as StrapModal } from 'reactstrap';
import styled from 'styled-components';
import {
  GET_ANIMATION_DURATION,
  GET_ANIMATION_EASING,
  GET_BORDER_RADIUS_1,
  GET_BORDER_RADIUS_3,
  GET_BORDER_RADIUS_4,
  GET_BOX_DANGER_SHADOW_1,
  GET_BOX_SHADOW_3,
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_1,
  GET_COLOR_SECONDARY_1,
  GET_FONT_FAMILY_SF_REGULAR,
} from '../../lib/themeGetters';
import { BorderedArrowIcon } from '../ArrowIcons/ArrowIcon';
import Loader from '../Loader/Loader';

export const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: ${GET_BORDER_RADIUS_1}px;
`;

export const Modal = styled(StrapModal)`
  .modal-content {
    z-index: 1501;
    border-radius: ${GET_BORDER_RADIUS_4}px;
    background-color: ${GET_COLOR_MAIN_1};
  }
`;

export const ModalBody = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
`;

export const DropdownMenu = styled(StrapDropdownMenu)`
  transition: box-shadow ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING};
  background-color: ${GET_COLOR_MAIN_1};
  border-radius: ${GET_BORDER_RADIUS_3}px;
  border: none;
`;

export const DropdownItem = styled(StrapDropdownItem)<{ $isActive?: boolean }>`
  ${p => (p.$isActive ? `background-color: ${GET_COLOR_SECONDARY_1(p)}` : '')};
  ${p => (p.$isActive ? `color: ${GET_COLOR_MAIN_1(p)}` : '')};
  ${p => (p.$isActive ? `border-radius: 12px;` : '')};
  :hover,
  :focus {
    background-color: ${GET_COLOR_SECONDARY_1};
    color: ${GET_COLOR_MAIN_1};
    border-radius: ${GET_BORDER_RADIUS_3}px;
  }
  :hover {
    box-shadow: ${props => {
      if (props.noShadow) {
        return 'none';
      }
      if (props.error) {
        return GET_BOX_DANGER_SHADOW_1;
      } else {
        return GET_BOX_SHADOW_3;
      }
    }};
  }
`;

export const DropdownItemButton = styled(StrapDropdownItem)<{ $isActive?: boolean }>`
  ${p => (p.$isActive ? `background-color: ${GET_COLOR_SECONDARY_1(p)}` : '')};
  ${p => (p.$isActive ? `color: ${GET_COLOR_MAIN_1(p)}` : '')};
  ${p => (p.$isActive ? `border-radius: 12px;` : '')};
  margin-top: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-right: 16px;
  :hover,
  :focus {
    background-color: ${GET_COLOR_SECONDARY_1};
    color: ${GET_COLOR_MAIN_1};
    border-radius: 12px;
  }
`;

export const MenuItem = styled(NavLink)<{}>`
  color: ${GET_COLOR_CONTRAST_1};
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: -2px;
  height: 100%;
  text-decoration: unset;
  align-items: center;
  display: flex;
  border-bottom: solid;
  font-size: 17px;
  font-family: ${GET_FONT_FAMILY_SF_REGULAR};
  border-bottom-color: rgba(0, 0, 0, 0);
  &.active {
    color: ${GET_COLOR_SECONDARY_1};
    border-bottom: solid;
  }
  :hover {
    text-decoration: unset;
  }
`;

export const Description = styled.div`
  font-size: 1.1rem;
  opacity: 0.48;
`;

const LeftArrowContainer = styled.div`
  position: absolute;
  left: -15px;
  top: 40%;
  z-index: 9;
`;

const RightArrowContainer = styled.div`
  position: absolute;
  right: -15px;
  top: 40%;
`;
interface ArrowProps {
  onClick?: () => void;
}

export const RightArrow = memo((props: ArrowProps) => {
  const { onClick } = props;

  return (
    <RightArrowContainer className="arrow" onClick={onClick}>
      <BorderedArrowIcon />
    </RightArrowContainer>
  );
});

export const LeftArrow = memo((props: ArrowProps) => {
  const { onClick } = props;

  return (
    <LeftArrowContainer className="arrow" onClick={onClick}>
      <BorderedArrowIcon rotate={180} />
    </LeftArrowContainer>
  );
});

const LoaderMaskWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
`;

interface LoaderMaskProps {
  isActive?: boolean;
}

export const LoaderMask = memo((props: LoaderMaskProps) => {
  const { isActive } = props;

  return isActive ? (
    <LoaderMaskWrapper>
      <Loader />
    </LoaderMaskWrapper>
  ) : null;
});

export const InstructionsPageDescription = styled.div`
  font-size: 18px;
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
`;

export const BlockMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
`;
