import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useLayoutLinks from '../../hooks/useLayoutLinks';
import { GET_COLOR_MAIN_1 } from '../../lib/themeGetters';
import { UserUserTierState } from '../../types/graphql';
import { AuthContext } from '../AuthProvider/AuthProvider';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import NavBurger from '../NavBurger/NavBurger';
import PrivateHeaderActions from '../PrivateHeaderActions/PrivateHeaderActions';
import PrivateHeaderMenu from '../PrivateHeaderMenu/PrivateHeaderMenu';
import SubscriptionBanner from '../SubscriptionBanner/SubscriptionBanner';

interface IProps {}

const LayoutWrapper = styled.div`
  background-color: ${GET_COLOR_MAIN_1};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Blocker = styled.div`
  width: 105%;
  height: calc(100% - 230px);
  position: absolute;
  background: rgb(0 0 0 / 25%);
  z-index: 9;
  //pointer-events: none;
  top: 230px;
`;

const PrivateLayout: React.FC<React.PropsWithChildren<IProps>> = props => {
  const authContext = useContext(AuthContext);
  const user = authContext.profile;
  const history = useHistory();

  const { getLinks } = useLayoutLinks('private');

  const headerLinks = useMemo(() => getLinks('header'), [getLinks]);
  const renderActions = useCallback(() => <PrivateHeaderActions />, []);
  const renderMenu = useCallback(() => <PrivateHeaderMenu links={getLinks('header')} />, [getLinks]);
  const footerLinks = useMemo(() => getLinks('footer'), [getLinks]);
  const renderBurger = useCallback(() => <NavBurger links={headerLinks} />, [headerLinks]);

  useEffect(() => {
    if (!user) {
      history.push('/');
    }
  }, [history, user]);

  if (!user) {
    return null;
  }
  return (
    <LayoutWrapper>
      <Header renderBurger={renderBurger} renderActions={renderActions} renderMenu={renderMenu} />
      <SubscriptionBanner />
      {user.tier?.isTrial === false && user.tier?.state === UserUserTierState.EXPIRED && <Blocker></Blocker>}
      <>{props.children}</>
      <Footer links={footerLinks} />
    </LayoutWrapper>
  );
};

export default React.memo(PrivateLayout);
