import React from 'react';
import styled from 'styled-components';
import { GET_FONT_FAMILY_OPEN_SANS_MEDIUM, GET_FONT_WEIGHT_BOLD } from '../../lib/themeGetters';

const Container = styled.h2`
  font-weight: ${GET_FONT_WEIGHT_BOLD};
  margin-bottom: 0;
  word-break: break-word;
  font-size: 41px;
  font-family: ${GET_FONT_FAMILY_OPEN_SANS_MEDIUM};
`;

interface IProps {
  className?: string;
}

const Title: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { className, children } = props;
  return <Container className={className}>{children}</Container>;
};

export default React.memo(Title);
