import React, { useCallback } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { ILayoutLink } from '../../hooks/useLayoutLinks';
import { GET_COLOR_CONTRAST_1, GET_COLOR_SECONDARY_1, GET_FONT_FAMILY_SF_REGULAR } from '../../lib/themeGetters';

interface IProps {
  links: ILayoutLink[];
  className?: string;
}

export const MenuItem = styled(NavHashLink)<{}>`
  color: ${GET_COLOR_CONTRAST_1};
  margin-bottom: -2px;
  height: 100%;
  text-decoration: unset;
  align-items: center;
  display: flex;
  border-bottom: solid;
  font-size: 17px;
  font-family: ${GET_FONT_FAMILY_SF_REGULAR};
  border-bottom-color: rgba(0, 0, 0, 0);
  &.active {
    color: ${GET_COLOR_SECONDARY_1};
    border-bottom: solid;
  }
  :hover {
    text-decoration: unset;
  }
`;

const HeaderMenu: React.FC<IProps> = props => {
  const links = props.links;
  const className = props.className;

  const handleScroll = useCallback((el: HTMLElement) => el.scrollIntoView({ behavior: 'auto', block: 'center' }), []);

  const renderLink = useCallback(
    (item: ILayoutLink) => (
      <MenuItem scroll={handleScroll} key={item.link} to={item.link}>
        {item.text}
      </MenuItem>
    ),
    [handleScroll]
  );

  return (
    <div className={`d-flex justify-content-between align-items-center h-100 flex-wrap flex-grow-1 ${className}`}>
      {links.map(renderLink)}
    </div>
  );
};

export default React.memo(HeaderMenu);
