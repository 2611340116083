import React from 'react';
import PublicLayout from '../PublicLayout/PublicLayout';
import PrivateLayout from '../PrivateLayout/PrivateLayout';

interface IProps {}

export interface ILayoutContextValue {
  public: typeof PublicLayout;
  private: typeof PrivateLayout;
}

const Layouts: ILayoutContextValue = {
  public: PublicLayout,
  private: PrivateLayout,
};

export const LayoutContext = React.createContext<ILayoutContextValue>(Layouts);

const LayoutProvider: React.FC<IProps> = props => {
  return <LayoutContext.Provider value={Layouts}>{props.children}</LayoutContext.Provider>;
};

export default LayoutProvider;
