import React, { useCallback } from 'react';
import { ILayoutLink } from '../../hooks/useLayoutLinks';
import { MenuItem } from '../Common/Common';

interface IProps {
  links: ILayoutLink[];
  className?: string;
}

const PrivateHeaderMenu: React.FC<IProps> = props => {
  const links = props.links;

  const renderLink = useCallback(
    (item: ILayoutLink) => (
      <MenuItem key={item.link} to={item.link}>
        {item.text}
      </MenuItem>
    ),
    []
  );

  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap flex-grow-1 h-100">
      {links.map(renderLink)}
    </div>
  );
};

export default React.memo(PrivateHeaderMenu);
