import React, { useCallback } from 'react';
import { Modal, ModalBody } from '../Common/Common';
import { Modal as StrapModal } from 'reactstrap';

interface IProps {
  form: { closeModal: () => void; isModalOpened?: boolean };
  size?: StrapModal['props']['size'];
}

const ModalForm: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { form, children, size } = props;
  const { closeModal, isModalOpened } = form;
  const handleModalClick = useCallback(evt => {
    if (evt) {
      evt.stopPropagation();
    }
  }, []);

  return (
    <Modal size={size} onClick={handleModalClick} toggle={closeModal} isOpen={isModalOpened}>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default React.memo(ModalForm);
