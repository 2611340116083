import React, { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  GET_ANIMATION_DURATION,
  GET_ANIMATION_EASING,
  GET_BOX_SHADOW_3,
  GET_COLOR_MAIN_3,
  GET_COLOR_SECONDARY_2,
  GET_INTERACTIONS_OPACITY,
} from '../../lib/themeGetters';
import { TIconProps } from '../../types/local';

interface IProps extends TIconProps {
  rotate?: number;
}

const ArrowIconContainer = styled.div<{ width?: number; height?: number; $rotate?: number }>`
  width: ${p => (p.width ? Math.round(p.width * 1.33) : 32)}px;
  height: ${p => (p.height ? Math.round(p.height * 1.33) : 32)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GET_COLOR_SECONDARY_2};
  border-radius: 50%;
  transition: background-color 0.3s ease, box-shadow ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING};

  cursor: pointer;
  :hover {
    box-shadow: ${GET_BOX_SHADOW_3};
    background-color: ${GET_COLOR_MAIN_3};
  }
`;

const Icon = styled.div<{ $rotate?: number }>`
  display: flex;
  align-items: center;
  ${p => (typeof p.$rotate === 'number' ? `transform: rotate(${p.$rotate}deg);` : '')}
  justify-content: center;
  transition: opacity ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING};
  :hover {
    opacity: ${GET_INTERACTIONS_OPACITY};
  }
`;

export const ArrowIcon: React.FC<IProps> = props => {
  const { width = 24, height = 24, opacity = 1, onClick, rotate } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  return (
    <Icon $rotate={rotate}>
      <svg
        onClick={onClick}
        className="cursor-pointer"
        opacity={opacity}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.5 18.5L19 12L12.5 5.5" stroke={color} strokeWidth="2" strokeLinecap="square" />
        <path d="M4 12H18" stroke={color} strokeWidth="2" strokeLinecap="square" />
      </svg>
    </Icon>
  );
};

export const BorderedArrowIcon: React.FC<IProps> = props => {
  const { width = 24, height = 24, opacity = 1, onClick, rotate } = props;
  return (
    <ArrowIconContainer width={width} height={height}>
      <ArrowIcon rotate={rotate} width={width} height={height} onClick={onClick} opacity={opacity}></ArrowIcon>
    </ArrowIconContainer>
  );
};

export default memo(ArrowIcon);
