import React, { useCallback, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  GET_ANIMATION_EASING,
  GET_BOX_SHADOW_3,
  GET_COLOR_MAIN_3,
  GET_COLOR_PRIMARY_1,
  GET_COLOR_SECONDARY_2,
  GET_ANIMATION_DURATION,
} from '../../lib/themeGetters';
import { TIconProps } from '../../types/local';

const CloseIconContainer = styled.div<{ width?: number; height?: number }>`
  width: ${p => p.width || 32}px;
  height: ${p => p.height || 32}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GET_COLOR_SECONDARY_2};
  border-radius: 50%;

  transition: background-color ${GET_ANIMATION_DURATION} ease,
    box-shadow ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING};
  cursor: pointer;
  :hover {
    box-shadow: ${GET_BOX_SHADOW_3};
    background-color: ${GET_COLOR_MAIN_3};
  }
`;

interface IProps extends TIconProps {
  onClick?: () => void;
  isWrapped?: boolean;
  className?: string;
  strokeColor?: string;
}

const CloseIcon: React.FC<IProps> = props => {
  const { className, width = 24, height = 24, opacity = 1, onClick, isWrapped } = props;

  const handleCancel: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      onClick?.();
    },
    [onClick]
  );

  const theme = useTheme();
  const strokeColor = props.strokeColor || GET_COLOR_PRIMARY_1({ theme });

  const content = useMemo(
    () => (
      <svg
        width={width}
        height={height}
        opacity={opacity}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 7.99683L16 15.9968" stroke={strokeColor} strokeWidth="2" strokeLinecap="square" />
        <path d="M16 7.99683L8 15.9968" stroke={strokeColor} strokeWidth="2" strokeLinecap="square" />
      </svg>
    ),
    [height, opacity, strokeColor, width]
  );
  return isWrapped ? (
    <CloseIconContainer width={Math.round(width * 1.333)} height={Math.round(height * 1.333)} onClick={handleCancel}>
      {content}
    </CloseIconContainer>
  ) : (
    <div
      className={`cursor-pointer d-flex align-items-center justify-content-center ${className ? className : ''}`}
      onClick={handleCancel}
    >
      {content}
    </div>
  );
};

export default React.memo(CloseIcon);
