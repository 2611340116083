import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledBottomContainer = styled.div`
  word-break: break-word;
`;

interface IProps extends HTMLAttributes<'div'> {}

const FormBottomContainer: React.FC<IProps> = props => {
  const { children, className } = props;
  return <StyledBottomContainer className={className}>{children}</StyledBottomContainer>;
};

export default React.memo(FormBottomContainer);
