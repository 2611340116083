import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useForgotPasswordForm from '../../hooks/useForgotPasswordForm';
import useLoginUserForm from '../../hooks/useLoginUserForm';
import useRegisterUserForm from '../../hooks/useRegisterUserForm';
import { AuthContext } from '../AuthProvider/AuthProvider';
import Button from '../Button/Button';
import Col from '../Col/Col';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from '../LoginForm/LoginForm';
import ModalForm from '../ModalForm/ModalForm';
import RegisterForm from '../RegisterForm/RegisterForm';
import ResetPasswordSuccessModal from '../ResetPasswordSuccessModal/ResetPasswordSuccessModal';

const StyledCol = styled(Col) <{ $vertical?: boolean }>`
  display: ${p => (p.$vertical ? 'flex' : 'inline-flex')};
  flex-direction: ${p => (p.$vertical ? 'column' : 'row')};
  align-items: center;
  justify-content: space-evenly;
  padding-left: 0;
  padding-right: 0;
`;

interface IProps {
  openRegisterModal?: boolean
  openLoginModal?: boolean
  vertical?: boolean;
}

const HeaderActions: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);
  const { vertical, openLoginModal, openRegisterModal } = props;
  const forgotPasswordForm = useForgotPasswordForm();
  const loginForm = useLoginUserForm(forgotPasswordForm, openLoginModal);
  const registerForm = useRegisterUserForm(openRegisterModal);

  return (
    <>
      <StyledCol
        className={vertical ? 'w-100 px-5 mt-5' : 'justify-content-end'}
        $vertical={vertical}
        xs={12}
        md={12}
        lg={12}
      >
        <Button
          className={vertical ? 'w-100' : ''}
          onClick={loginForm.handleLogin}
          color="secondary"
          visibility="medium"
        >
          {t(loginForm.loginButtonLabel)}
        </Button>
        {!profile &&
          <Button
            className={vertical ? 'w-100 mt-3' : 'ml-4'}
            color="secondary"
            visibility="strong"
            onClick={registerForm.handleRegister}
          >
            {t('a_register')}
          </Button>
        }
      </StyledCol>
      <ModalForm form={loginForm}>
        <LoginForm form={loginForm} />
      </ModalForm>
      <ModalForm form={registerForm}>
        <RegisterForm form={registerForm} showLoginModal={loginForm.openModal} />
      </ModalForm>
      <ModalForm form={forgotPasswordForm}>
        <ForgotPasswordForm form={forgotPasswordForm} />
      </ModalForm>
      <ResetPasswordSuccessModal form={forgotPasswordForm} />
    </>
  );
};

export default React.memo(HeaderActions);
