import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TUseLoginUserForm } from '../../hooks/useLoginUserForm';
import { LoginInput } from '../../types/graphql';
import Button from '../Button/Button';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FieldSet from '../FieldSet/FieldSet';
import FormBottomContainer from '../FormBottomContainer/FormBottomContainer';
import Input from '../Input/Input';
import Title from '../Title/Title';

interface IProps {
  form: TUseLoginUserForm;
}

const LoginForm: React.FC<IProps> = props => {
  const { isLoading, error, onSubmit, onPasswordReset } = props.form;
  useDocumentTitle('t_login');

  const { t } = useTranslation();

  const requiredText = t('e_required');
  const wrongEmailText = t('e_wrong_email');
  const forgotPasswordText = t('a_forgot_password');
  const loginText = t('a_login');
  const emailHelp = t('d_login_email');
  const passwordHelp = t('d_login_password');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(wrongEmailText).required(requiredText),
      password: Yup.string().required(requiredText),
    }),

    onSubmit: values => {
      const data: LoginInput = values;
      onSubmit(data);
    },
  });

  return (
    <>
      <Title>{t('h_sign_in')}</Title>
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <FieldSet
          touched={formik.touched.email}
          error={formik.errors.email}
          id="email"
          label={t('email')}
          help={emailHelp}
        >
          <Input
            name="email"
            type="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </FieldSet>
        <FieldSet
          help={passwordHelp}
          touched={formik.touched.password}
          error={formik.errors.password}
          id="password"
          label={t('password')}
        >
          <Input
            autoComplete="password"
            name="password"
            type="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
          />
        </FieldSet>
        <FormBottomContainer className="d-flex justify-content-end">
          <Button onClick={onPasswordReset} disabled={isLoading} color="secondary" visibility="light">
            {forgotPasswordText}
          </Button>
          <Button type="submit" isLoading={isLoading} color="secondary" visibility="strong">
            {loginText}
          </Button>
        </FormBottomContainer>
        <ErrorMessage simple className="mt-2" error={error} />
      </form>
    </>
  );
};

export default React.memo(LoginForm);
