import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LocaleISO } from '../types/local';
import { GRAPH_ENDPOINT, IS_BROWSER } from './constants';
import { getFromLocalStorage } from './localStorage';

export const DEFAULT_LOCALE: LocaleISO = window.navigator.language.slice(0, 2) !== 'en' ? 'ru' : 'en';

// Define HTTP requests
export const getHttpMiddleware = () => {
  return new HttpLink({
    uri: GRAPH_ENDPOINT,
  });
};

const getLang = (lang?: string) => {
  if (lang) {
    return lang === 'en' ? 'en-En' : 'ru-Ru';
  }
  const locale = getFromLocalStorage('locale');
  if (locale) {
    return locale === 'en' ? 'en-En' : 'ru-Ru';
  }
  return locale || window.navigator.language;
};

const getUserTeam = () => {
  const user = getFromLocalStorage('user');
  const storagedTeamId = getFromLocalStorage('teamId');

  const storagedTeam = user?.teamMembership?.find(membership => membership.team.id === storagedTeamId);

  return storagedTeam?.team || user?.teamMembership?.[0]?.team;
};

const getTeamId = () => {
  const team = getUserTeam();
  return team?.id;
};

// Add auth headers to requests
export const getAuthMiddleware = (lang?: string) => {
  return setContext((_, { headers }) => {
    const teamId = getTeamId();
    const result = {
      headers: {
        ...headers,
        'x-locale': getLang(lang), //locale,
        'x-team-id': getFromLocalStorage('invite_key'),
      },
    };

    if (teamId) {
      result.headers['x-team'] = teamId;
    }

    // get the authentication token from local storage if it exists
    const token = getFromLocalStorage('accessToken');
    if (token) {
      (result.headers as any).Authorization! = `${token}`;
    }

    // return the headers to the context so httpLink can read them
    return result;
  });
};

// Add auth headers to requests
export const getErrorMiddleware = () => {
  return onError(({ graphQLErrors }) => {
    const errors = [...(graphQLErrors || [])];
    errors.forEach(error => {
      if (!IS_BROWSER || !error) {
        return;
      }

      if (error.extensions?.code === 'UNAUTHENTICATED') {
        // EventService.fire(EventService.EVENTS.UNAUTHENTICATED_ERROR_FOUND);
      } else if (error.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        // Raven.captureMessage(error.message || ERROR_FALLBACK_KEY);
      } else {
        // Raven.captureMessage(error.message || ERROR_FALLBACK_KEY);
        // const message = error.message || ERROR_FALLBACK_KEY;
        // toast(message, { ...COMMON_NOTIFICATION_CONFIG, type: 'error' });
      }
    });
  });
};
