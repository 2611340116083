import React, { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';
import {
  GET_COLOR_CONTRAST_1,
  GET_COLOR_CONTRAST_2,
  GET_COLOR_CONTRAST_3,
  GET_COLOR_MAIN_1,
  GET_TEXT_FONT_FAMILY,
} from '../../lib/themeGetters';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {}

const StyledInput = styled.input<{ color?: string }>`
  width: 100%;
  padding: 13px;
  border: solid;
  border-width: 2px;
  border-color: ${GET_COLOR_CONTRAST_2};
  background-color: ${GET_COLOR_MAIN_1};
  border-radius: 10px;
  font-family: ${GET_TEXT_FONT_FAMILY};
  color: ${p => (p.color ? p.color : GET_COLOR_CONTRAST_1)};
  transition: border-color ease 0.5s;
  ::placeholder {
    color: ${GET_COLOR_CONTRAST_1};
  }
  :focus-visible {
    outline: none;
  }
  :hover {
    border-color: ${p => (p.color ? p.color : GET_COLOR_CONTRAST_3)};
  }
`;

const Input: React.FC<IProps> = props => {
  const { onChange, color, ...inputProps } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      const value = event?.target?.value ?? null;
      if (value === null) {
        return;
      }

      onChange(event);
    },
    [onChange]
  );

  return <StyledInput value={props.value} onChange={handleChange} {...inputProps} />;
};

export default Input;
