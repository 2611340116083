import { ApolloError, FetchResult } from '@apollo/client';
import { FormikValues } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResetUserPasswordMutation, useResetUserPasswordMutation } from '../types/graphql';
import useEntityTimer, { IEntityTimerResult } from './useEntityTimer';

export type TUseForgotPasswordForm = {
  isModalOpened: boolean;
  openModal: () => void;
  onSubmit: (values: FormikValues) => void;
  closeModal: () => void;
  error?: ApolloError | null;
  isLoading: boolean;
  isResetDone: boolean;
  timer: IEntityTimerResult;
  closeSuccessModal: () => void;
};

function useForgotPasswordForm(): TUseForgotPasswordForm {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isResetDone, setIsResetDone] = useState(false);
  const [resetPassword, resetPasswordResult] = useResetUserPasswordMutation();

  const timer = useEntityTimer('resetPasswordTimer', 60);
  const runTimer = timer.runTimer;

  const { t } = useTranslation();

  const isLoading = resetPasswordResult.loading;
  const error = resetPasswordResult.error;

  const openModal = useCallback(() => {
    setIsModalOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  const handleResendSuccess = useCallback(
    (response: FetchResult<ResetUserPasswordMutation>) => {
      const userAuthResponse = response?.data?.resetUserPassword;
      if (!userAuthResponse) {
        throw new Error(t('auth_error'));
      }
      runTimer();
      closeModal();
      setIsResetDone(true);
    },
    [closeModal, runTimer, t]
  );

  const handleResendError = useCallback(() => {}, []);

  const closeSuccessModal = useCallback(() => setIsResetDone(false), []);

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const email = values.email;
      resetPassword({ variables: { email } }).then(handleResendSuccess).catch(handleResendError);
    },
    [handleResendError, handleResendSuccess, resetPassword]
  );

  const ctx = useMemo(
    () => ({
      isModalOpened,
      openModal,
      onSubmit: handleSubmit,
      closeModal,
      isLoading,
      error,
      timer,
      isResetDone,
      closeSuccessModal,
    }),
    [isModalOpened, openModal, handleSubmit, closeModal, isLoading, error, timer, isResetDone, closeSuccessModal]
  );

  return ctx;
}

export default useForgotPasswordForm;
