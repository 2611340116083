import { useApolloClient } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';
import useAcceptUserInvite from '../../hooks/useAcceptUserInvite';
import { IS_BROWSER } from '../../lib/constants';
import { getFromLocalStorage, removeFromLocalStorage, writeToLocalStorage } from '../../lib/localStorage';
import { AuthResponseFragment, useProfileQuery, UserFragment } from '../../types/graphql';
import { CallbackFnWithoutArguments } from '../../types/local';

// Check auth logic
export const getIsTokenStored = (): boolean => {
  if (!IS_BROWSER) {
    return false;
  }

  const token = getFromLocalStorage('accessToken');
  return Boolean(token);
};

interface IProps {}

interface IAuthContextValue {
  isAuthorized: boolean;
  login: (authResponse: AuthResponseFragment, callback?: CallbackFnWithoutArguments) => void;
  logout: (callback?: CallbackFnWithoutArguments) => void;
  reloadProfile: () => void;
  profile: UserFragment | null;
  isLoading: boolean;
}

export const AuthContext = React.createContext<IAuthContextValue>({
  profile: null,
  isAuthorized: false,
  logout: () => {},
  login: () => {},
  reloadProfile: () => {},
  isLoading: false,
});

// Component to redirect if not authorized
const AuthProvider: React.FC<IProps> = props => {
  const [isAuthorized, setAuthState] = useState(getIsTokenStored());
  const client = useApolloClient();
  // Fetch user profile if token stored locally
  const profileResult = useProfileQuery({
    skip: !isAuthorized,
    fetchPolicy: 'no-cache',
  });

  // Extract data from response
  const userProfile = profileResult?.data?.profile || null;
  const profile = isAuthorized ? userProfile : null;
  const isLoading = profileResult.loading;
  const refetch = profileResult.refetch;

  const { acceptUserInvite } = useAcceptUserInvite();

  // Update UI state based on data stored in localStorage
  const updateAuthState = useCallback((callback?: CallbackFnWithoutArguments, accessToken?: string) => {
    setAuthState(!!accessToken);
    if (callback) {
      callback();
    }
  }, []);

  // Store auth response data and update UI state
  const handleLogin = useCallback<IAuthContextValue['login']>(
    (authResponse, callback) => {
      writeToLocalStorage('accessToken', authResponse.accessToken);
      writeToLocalStorage('refreshToken', authResponse.refreshToken);
      writeToLocalStorage('user', authResponse.user);
      updateAuthState(callback, authResponse.accessToken);
      acceptUserInvite(authResponse.user);
    },
    [acceptUserInvite, updateAuthState]
  );

  // Remove auth related data from local storage, update UI state and clear ApolloClient
  const handleLogout = useCallback<IAuthContextValue['logout']>(
    callback => {
      removeFromLocalStorage('accessToken');
      removeFromLocalStorage('refreshToken');
      removeFromLocalStorage('user');
      removeFromLocalStorage('teamId');

      updateAuthState(callback);

      client.clearStore();
    },
    [updateAuthState, client]
  );

  const value = useMemo(
    (): IAuthContextValue => ({
      isAuthorized: isAuthorized,
      logout: handleLogout,
      login: handleLogin,
      profile,
      reloadProfile: refetch,
      // For testing tier libraryManagement ADD
      // profile: {
      //   ...profile,

      //   teamMembership: [
      //     {
      //       ...profile?.teamMembership[0],
      //       //@ts-ignore
      //       role: { ...profile?.teamMembership[0]?.role, libraryManagement: ['DELETE'] },
      //     },
      //   ],
      // },
      // For testing tier templateCategoryLimit
      // {
      //   ...profile,

      //   tier: {
      //     ...profile?.tier,
      //     //@ts-ignore
      //     tier: { ...profile?.tier?.tier, settings: { ...profile?.tier?.tier?.settings, templateCategoryLimit: 2 } },
      //   },
      // },
      isLoading: isLoading,
    }),
    [isAuthorized, handleLogout, handleLogin, profile, refetch, isLoading]
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
