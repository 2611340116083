import React from 'react';
import styled from 'styled-components';

const StyledText = styled.div`
  opacity: 0.34;
`;

interface IProps {
  className?: string;
}

const HelpText: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { children, ...otherProps } = props;
  return <StyledText {...otherProps}>{children}</StyledText>;
};

export default React.memo(HelpText);
