import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ACTIVE_LANGUAGES } from '../../lib/constants';
import {
  GET_COLOR_MAIN_2,
  GET_COLOR_SECONDARY_1,
  GET_INTERACTIONS_OPACITY,
  GET_PADDING_1,
  GET_PADDING_2,
} from '../../lib/themeGetters';
import { LocaleISO } from '../../types/local';
import { I18nContext } from '../I18nProvider/I18nProvider';

interface IProps {
  contentClassName?: string;
}

const Container = styled.div<{ $isLoading?: boolean }>`
  border-radius: 16px;
  padding: ${GET_PADDING_1};
  background-color: ${GET_COLOR_MAIN_2};
  display: inline-flex;
  cursor: ${p => (p.$isLoading ? 'not-allowed' : 'pointer')};
  opacity: ${p => (p.$isLoading ? GET_INTERACTIONS_OPACITY(p) : 1)};
`;

const IconContainer = styled.div<{ selected?: boolean; isFirst?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 12px;
  ${p => (p.selected ? `background-color: ${GET_COLOR_SECONDARY_1(p)}` : 'border-color: rgba(0,0,0,0)')};
  transition: opacity 0.3s ease;
  padding: ${GET_PADDING_2};
  :hover {
    opacity: 0.5;
  }
`;

const LangImg = styled.img`
  min-width: 24px;
  min-height: 25px;
`;

const getImageSrc = (lng: LocaleISO) => {
  if (lng === 'ru') {
    return '/images/icons/ruFlagIcon.png';
  }
  if (lng === 'en') {
    return '/images/icons/enFlagIcon.png';
  }
  return '/images/icons/ruFlagIcon.png';
};

const LangSwitch: React.FC<IProps> = props => {
  const { t } = useTranslation();

  const { isExternalTranslationsLoading, activeLanguage, changeLanguage } = useContext(I18nContext);

  const handleChangeLanguage = useCallback(
    (lng: LocaleISO) => () => {
      if (isExternalTranslationsLoading || lng === activeLanguage) {
        return;
      }
      changeLanguage(lng);
    },
    [activeLanguage, changeLanguage, isExternalTranslationsLoading]
  );

  const renderItem = useCallback(
    (lng: LocaleISO, index: number) => {
      return (
        <IconContainer key={index} selected={activeLanguage === lng}>
          <button
            key={lng}
            onClick={handleChangeLanguage(lng)}
            className={`button p-2 btn d-flex align-items-center justify-content-start`}
          >
            <LangImg alt={t(`toggle_lang_${lng}`)} src={getImageSrc(lng)} />
          </button>
        </IconContainer>
      );
    },
    [activeLanguage, handleChangeLanguage, t]
  );

  const renderPopoverBody = useCallback(() => ACTIVE_LANGUAGES.map(renderItem), [renderItem]);

  return (
    <>
      <Container $isLoading={isExternalTranslationsLoading}>{renderPopoverBody()}</Container>
    </>
  );
};

export default React.memo(LangSwitch);
