import { FormikErrors } from 'formik';
import React from 'react';
import styled from 'styled-components';
import {
  GET_COLOR_CONTRAST_1,
  GET_FONT_WEIGHT_NORMAL,
  GET_FONT_WEIGHT_SEMI_BOLD,
  GET_MARGIN_1,
  GET_MARGIN_2,
  StyledFnProps,
} from '../../lib/themeGetters';
import { TFontSize } from '../../types/local';
import ErrorText from '../ErrorText/ErrorText';
import HelpText from '../HelpText/HelpText';

interface IProps {
  label?: string;
  labelSize?: TFontSize;
  id: string;
  color?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  labelClassName?: string;
  help?: string;
  touched?: boolean;
  className?: string;
}
type TLabel = { color?: string; $labelSize?: TFontSize };

const StyledFieldSet = styled.fieldset`
  display: block;
  width: 100%;
  border: none;
  margin-bottom: ${GET_MARGIN_2};
  padding: 0;
`;

const getCorrectFontSize = (p: StyledFnProps<TLabel>) => {
  if (p.$labelSize === 'large') {
    return '1.3rem';
  }
  if (p.$labelSize === 'small') {
    return '0.7rem';
  }
  return '1rem';
};

const getCorrectFontWeight = (p: StyledFnProps<TLabel>) => {
  if (p.$labelSize === 'large') {
    return GET_FONT_WEIGHT_SEMI_BOLD;
  }
  return GET_FONT_WEIGHT_NORMAL;
};

const StyledLabel = styled.label<TLabel>`
  display: block;
  margin-bottom: ${GET_MARGIN_1};
  color: ${p => (p.color ? p.color : GET_COLOR_CONTRAST_1)};
  font-size: ${getCorrectFontSize};
  font-weight: ${getCorrectFontWeight};
`;

const FieldSet: React.FC<IProps> = props => {
  const { label, id, color, error, labelSize, labelClassName, help, touched, className } = props;

  return (
    <StyledFieldSet className={className}>
      {label ? (
        <StyledLabel className={labelClassName} $labelSize={labelSize} color={color} htmlFor={id}>
          {label}
        </StyledLabel>
      ) : null}
      {props.children}
      <HelpText className="mt-2">{help}</HelpText>
      <ErrorText touched={touched} error={error} />
    </StyledFieldSet>
  );
};

export default React.memo(FieldSet) as unknown as typeof FieldSet;
