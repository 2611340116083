import React from 'react';
import { DefaultTheme, ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { getFromLocalStorage, writeToLocalStorage } from '../../lib/localStorage';
import { THEME_WHITE } from '../../themes/themeWhite';
import { THEME_BLACK } from '../../themes/themeBlack';
import { GlobalStyles } from '../../themes/assets/globalStyles';
import { BootstrapStyles } from '../../themes/assets/bootstrapStyles';

interface IExternalProps {}

interface IProps extends IExternalProps {}

interface IState {
  activeTheme: DefaultTheme;
}

export type ThemeNames = 'black' | 'white';

interface IThemeContextValue {
  activeThemeName: ThemeNames;
  toggle: () => void;
}

export const ThemeContext = React.createContext<IThemeContextValue>({
  activeThemeName: 'white',
  toggle: () => {},
});

const DEFAULT_THEME = THEME_WHITE;

class ThemeProvider extends React.PureComponent<IProps, IState> {
  private getInitialActiveTheme = (): DefaultTheme => {
    const themeName = getFromLocalStorage('themeName') as ThemeNames;
    if (!themeName) {
      return DEFAULT_THEME;
    }

    const themes: Record<ThemeNames, DefaultTheme> = {
      white: THEME_WHITE,
      black: THEME_BLACK,
    };

    return themes[themeName] || DEFAULT_THEME;
  };

  state: IState = {
    activeTheme: this.getInitialActiveTheme(),
  };

  private toggleTheme = () => {
    const isWhite = this.state.activeTheme.name === THEME_WHITE.name;
    const payload = isWhite ? THEME_BLACK : THEME_WHITE;

    this.setState({ activeTheme: payload }, () => {
      writeToLocalStorage('themeName', this.state.activeTheme.name);
    });
  };

  private getContextValue = (): IThemeContextValue => {
    const { name } = this.state.activeTheme;

    return {
      activeThemeName: name as IThemeContextValue['activeThemeName'],
      toggle: this.toggleTheme,
    };
  };

  render() {
    const { children } = this.props;
    const { activeTheme } = this.state;
    const value = this.getContextValue();

    return (
      <StyledComponentsThemeProvider theme={activeTheme}>
        <GlobalStyles />
        <BootstrapStyles />
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
      </StyledComponentsThemeProvider>
    );
  }
}

export default ThemeProvider;
