import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GET_COLOR_CONTRAST_2 } from '../../lib/themeGetters';
import { UserFragment } from '../../types/graphql';
import { TFontSize } from '../../types/local';

interface IProps {
  user: UserFragment;
  editable?: boolean;
  size?: TFontSize;
}

const portraitSize = 42;

const getPortraitSize = (size?: TFontSize) => {
  if (!size) {
    return '';
  }
  if (size === 'small') {
    return `width: ${portraitSize * 0.76}px;
    height: ${portraitSize * 0.76}px;`;
  }
  if (size === 'large') {
    return `width: ${portraitSize * 1.2}px;
    height: ${portraitSize * 1.2}px;`;
  }
  return `width: ${portraitSize}px;
  height: ${portraitSize}px;`;
};

const Portrait = styled.div<{ portrait?: string | null; size?: TFontSize }>`
  min-width: 32px;
  max-width: 32px;
  max-height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: ${GET_COLOR_CONTRAST_2};
  ${p => (p.portrait ? `background-image: url('${p.portrait}')` : '')};
  background-position: center;
  background-size: cover;
  ${p => getPortraitSize(p.size)}
`;

const EditContainer = styled(Link)`
  font-size: 0.85rem;
`;

const ProfileHeader: React.FC<IProps> = props => {
  const { user, editable, size } = props;
  const { firstName, lastName, avatar } = user;
  const { t } = useTranslation();
  const editProfileText = t('a_edit_profile');
  return (
    <div className="d-flex flex-row align-items-center">
      <Portrait size={size} portrait={avatar} />
      <div className="ml-2 d-flex align-items-start flex-column justify-content-center">
        <span>{`${firstName} ${lastName}`}</span>
        {editable && <EditContainer to={`/users/edit/me`}>{editProfileText}</EditContainer>}
      </div>
    </div>
  );
};

export default React.memo(ProfileHeader);
