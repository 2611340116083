import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import SuspenseFallback from './components/SuspenseFallback/SuspenseFallback';
import App from './components/App/App';

import './styles/index.scss';

const fallback = <SuspenseFallback />;

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={fallback}>
      <App />
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
