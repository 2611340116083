import { FormikErrors } from 'formik';
import React from 'react';
import styled from 'styled-components';
import useHtmlParser from '../../hooks/useHtmlParser';
import { GET_TEXT_FONT_FAMILY, GET_COLOR_DANGER_1 } from '../../lib/themeGetters';

const StyledErrorDiv = styled.div`
  min-height: 22px;
`;

const StyledErrorText = styled.p`
  @keyframes popout {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  font-family: ${GET_TEXT_FONT_FAMILY};
  color: ${GET_COLOR_DANGER_1};
  animation: popout 0.3s ease-in-out;
`;

interface IProps {
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  touched?: boolean;
  className?: string;
}

const ErrorText: React.FC<IProps> = props => {
  const { error, touched = true, className } = props;
  const { parseHtml } = useHtmlParser();
  return (
    <StyledErrorDiv className={className}>
      {error && touched ? (
        <StyledErrorText>{typeof error === 'string' ? parseHtml(error) : error}</StyledErrorText>
      ) : null}
    </StyledErrorDiv>
  );
};

export default React.memo(ErrorText);
