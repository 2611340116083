import React, { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { GET_BOX_SHADOW_3, GET_COLOR_MAIN_1, GET_PADDING_4 } from '../../lib/themeGetters';

interface IProps {
  content: JSX.Element;
  buttonContent?: JSX.Element;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  contentStyle?: string;
}

const Container = styled.div<{ $contentStyle?: string }>`
  // padding: ${GET_PADDING_4};
  background-color: ${GET_COLOR_MAIN_1};
  box-shadow: ${GET_BOX_SHADOW_3};
  border-radius: 10px;
  ${p => (p.$contentStyle ? p.$contentStyle : '')}
`;

const Popper: React.FC<IProps> = props => {
  const { content, buttonContent, isOpen = false, onToggle, contentStyle } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen);

  const handleToggle = useCallback(
    (evt: BaseSyntheticEvent<MouseEvent>) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (typeof onToggle === 'function') {
        return onToggle(!isOpen);
      }
      setIsPopoverOpen(!isPopoverOpen);
    },
    [isOpen, isPopoverOpen, onToggle]
  );

  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      if (typeof onToggle === 'function') {
        return onToggle(false);
      }
      setIsPopoverOpen(false);
    },
    [onToggle]
  );

  const isOpened = useMemo(() => {
    if (typeof isOpen === 'boolean') {
      return isOpen;
    }
    return isPopoverOpen;
  }, [isOpen, isPopoverOpen]);

  const wrappedContent = useMemo(
    () => <Container $contentStyle={contentStyle}>{content}</Container>,
    [content, contentStyle]
  );

  return (
    <Popover
      isOpen={isOpened}
      positions={['bottom']}
      content={wrappedContent}
      onClickOutside={handleClickOutside}
      containerStyle={{ zIndex: '999', marginTop: '8px' }} // to overindexing other layouts e.g nav burger
    >
      <div onClick={handleToggle}>{buttonContent}</div>
    </Popover>
  );
};

export default React.memo(Popper);
