import * as React from 'react';
import styled from 'styled-components';
// import { GET_PADDING_1, GET_PADDING_5 } from '../../lib/themeGetters';

interface IProps {
  className?: string;
  size?: 'small' | 'default' | 'large';
  text?: string;
  isFull?: boolean;
}

const Wrapper = styled.div<Pick<IProps, 'isFull'>>`
  height: ${p => (p.isFull ? '100%' : 'auto')};
  width: ${p => (p.isFull ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const getSpinnerSize = (size: IProps['size']) => {
  if (size === 'small') {
    return 'spinner-border-sm';
  }
  if (size === 'large') {
    return 'spinner-border-lg';
  }
  return 'spinner-border-md';
};

const Loader: React.FC<IProps> = props => {
  const { isFull, size, text, className, children } = props;

  return (
    <Wrapper className={className} isFull={isFull}>
      <div className={`spinner-border ${getSpinnerSize(size)}`} role="status" />
      {children}
      {text && <p className="mt-3">{text}</p>}
    </Wrapper>
  );
};

export default Loader;
