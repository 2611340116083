import React, { useCallback } from 'react';

import { TUseForgotPasswordForm } from '../../hooks/useForgotPasswordForm';
import { Modal, ModalBody } from '../Common/Common';

import ResetPasswordSuccess from '../ResetPasswordSuccess/ResetPasswordSuccess';

interface IProps {
  form: TUseForgotPasswordForm;
}

const ResetPasswordSuccessModal: React.FC<IProps> = props => {
  const { form } = props;
  const { closeSuccessModal, isResetDone } = form;

  const handleCloseModal = useCallback(() => {
    closeSuccessModal?.();
  }, [closeSuccessModal]);

  return (
    <Modal toggle={handleCloseModal} isOpen={isResetDone}>
      <ModalBody>
        <ResetPasswordSuccess onSubmit={handleCloseModal} />
      </ModalBody>
    </Modal>
  );
};

export default React.memo(ResetPasswordSuccessModal);
