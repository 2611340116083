import React from 'react';
import Loader from '../Loader/Loader';

interface IProps {}

const SuspenseFallback: React.FC<IProps> = () => {
  return (
    <div className="suspense-fallback">
      <div className="pt-3">
        <Loader />
      </div>
    </div>
  );
};

export default React.memo(SuspenseFallback);
