import React, { useCallback } from 'react';
import * as Bootstrap from 'reactstrap';
import styled from 'styled-components';
import {
  GET_ANIMATION_DURATION,
  GET_ANIMATION_EASING,
  GET_BORDER_RADIUS_4,
  GET_BOX_SHADOW_3,
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_1,
  GET_COLOR_MAIN_2,
  GET_COLOR_PRIMARY_1,
  GET_COLOR_PRIMARY_2,
  GET_COLOR_SECONDARY_1,
  GET_COLOR_SECONDARY_2,
  GET_INTERACTIONS_OPACITY,
  GET_PADDING_4,
  GET_PADDING_5,
  StyledFnProps,
} from '../../lib/themeGetters';
import Loader from '../Loader/Loader';

const getBackgroundColor = (p: StyledFnProps<IButtonProps>) => {
  if (p.visibility === 'light') {
    return 'transparent';
  }

  if (p.color === 'main') {
    if (p.visibility === 'medium') {
      return GET_COLOR_MAIN_2(p);
    }

    return GET_COLOR_MAIN_1(p);
  }

  if (p.color === 'primary') {
    if (p.visibility === 'medium') {
      return GET_COLOR_PRIMARY_2(p);
    }

    return GET_COLOR_PRIMARY_1(p);
  }

  if (p.color === 'secondary') {
    if (p.visibility === 'medium') {
      return GET_COLOR_SECONDARY_2(p);
    }

    return GET_COLOR_SECONDARY_1(p);
  }

  return GET_COLOR_SECONDARY_1(p);
};

const getColor = (p: StyledFnProps<IButtonProps>) => {
  if (p.color === 'main') {
    if (p.visibility === 'light') {
      return GET_COLOR_MAIN_1(p);
    }
    return GET_COLOR_SECONDARY_1(p);
  }

  if (p.color === 'primary' && p.visibility === 'strong') {
    return '#ffffff';
  }

  if (p.color === 'secondary') {
    if (p.visibility === 'strong') {
      return GET_COLOR_MAIN_1(p);
    }

    if (p.visibility === 'light') {
      return GET_COLOR_SECONDARY_1(p);
    }

    return GET_COLOR_SECONDARY_1(p);
  }

  if (p.visibility === 'strong') {
    return GET_COLOR_MAIN_1(p);
  }

  if (p.color === 'primary') {
    return GET_COLOR_PRIMARY_1(p);
  }

  return GET_COLOR_CONTRAST_1(p);
};

const StyledButton = styled(props => {
  // Here we can extract styled props which is should not be provided to original component
  const { isLoading, hasIcon, ...buttonProps } = props;

  return <Bootstrap.Button {...buttonProps} />;
})<IButtonProps>`
  padding: ${p => (p.hasIcon ? '10px' : `${GET_PADDING_4(p)} ${GET_PADDING_5(p)}`)} !important;
  background-color: ${getBackgroundColor} !important;
  color: ${getColor} !important;
  border: none !important;
  border-radius: ${GET_BORDER_RADIUS_4}px !important;
  transition: opacity ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING},
    box-shadow ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING} !important;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')} !important;
  opacity: ${p => (p.disabled ? GET_INTERACTIONS_OPACITY(p) : 1)} !important;
  line-height: 1 !important;
  font-size: 16px;
  &:hover {
    // opacity: ${GET_INTERACTIONS_OPACITY} !important;
    box-shadow: ${p => (p.visibility === 'light' ? 'none' : GET_BOX_SHADOW_3)};
  }
  :focus-visible {
    outline: none;
    box-shadow: none;
  }
  :focus {
    outline: none;
    box-shadow: none;
  }
  :active:focus {
    box-shadow: none;
  }
`;

type BootstrapButtonProps = Omit<Bootstrap.ButtonProps, 'color'>;

export interface IButtonProps extends BootstrapButtonProps {
  isLoading?: boolean;
  color?: 'primary' | 'secondary' | 'main';
  visibility?: 'strong' | 'medium' | 'light';
}

const Button: React.FC<IButtonProps> = props => {
  const { disabled, isLoading, children, onClick, renderIcon } = props;

  const visibility = props.visibility || 'strong';
  const color = props.color || 'primary';
  const isDisabled = disabled || isLoading || false;

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!onClick || isDisabled) {
        return;
      }

      onClick(event);
    },
    [onClick, isDisabled]
  );

  const renderSpinner = useCallback(() => {
    if (!isLoading) {
      return null;
    }

    return <Loader size="small" className="mr-2" />;
  }, [isLoading]);

  return (
    <StyledButton
      hasIcon={renderIcon}
      {...props}
      onClick={handleClick}
      disabled={isDisabled}
      color={color}
      visibility={visibility}
    >
      <div className="d-flex justify-content-center align-items-center">
        {renderSpinner()}
        {renderIcon?.()}
        {children}
      </div>
    </StyledButton>
  );
};

export default React.memo(Button);
