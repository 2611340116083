import { ITemplatePreset, LocaleISO } from '../types/local';

export const PROJECT_NAME = 'publisher';

export const IS_BROWSER = typeof window !== 'undefined';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const GRAPH_ENDPOINT = '/graphql';

export const ERROR_FALLBACK_KEY = 'error';

export const SUPPORT_EMAIL = 'hello@publishare.io';
export const SUPPORT_EMAIL_URL = 'mailto:hello@publishare.io';
export const APPSTORE_URL = 'https://apps.apple.com/us/app/publishare/id1591009798';

export const PRIVATE_ENTRY_PAGE = '/users';

export enum DRAG_ITEM_TYPES {
  TEMPLATE = 'template',
  TEMPLATE_CATEGORY = 'templateCategory',
}

export enum DROP_ITEM_TYPES {
  LIBRARY_CATEGORY = 'libraryCategory',
}

export const BASE_LIMIT = 2000;
export const PAGINATION_BASE_LIMIT = 20;
export const FETCH_MORE_BASE_LIMIT = 500;

export const ACTIVE_LANGUAGES: LocaleISO[] = ['ru', 'en'];

export const TEMPLATE_PRESETS: ITemplatePreset[] = [
  { ratio: '1:1', width: 120, height: 120 },
  { ratio: '4:5', width: 96, height: 120 },
  { ratio: '5:4', width: 150, height: 120 },
  { ratio: '9:16', width: 68, height: 120 },
  { ratio: '16:9', width: 213, height: 120 },
];

export const TEMPLATE_PRESETS_LARGE: ITemplatePreset[] = [
  { ratio: '1:1', width: 220, height: 220 },
  { ratio: '4:5', width: 176, height: 220 },
  { ratio: '5:4', width: 275, height: 220 },
  { ratio: '9:16', width: 123.75, height: 220 },
  { ratio: '16:9', width: 391, height: 220 },
];

export const MAX_FILES = 25;

export const TEXT_TAG_ID = 4;
export const BACKGROUND_TEXT_TAG_ID = 1;
