import { useApolloClient } from '@apollo/client';
import i18n, { Resource } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { getFromLocalStorage, writeToLocalStorage } from '../../lib/localStorage';
import { LOCAL_TRANSLATIONS } from '../../lib/localTranslations';
import {
  AllWebTranslationsQuery,
  useAllWebTranslationsLazyQuery,
  useWebTranslationsUpdatedAtQuery,
} from '../../types/graphql';
import { LocaleISO } from '../../types/local';
import { StaticKeys } from './staticKeys';

export const DEFAULT_LOCALE: LocaleISO = window.navigator.language.slice(0, 2) !== 'en' ? 'ru' : 'en';

interface IProps {}

export interface I18nFn {
  (key: StaticKeys | string): string;
  activeLanguage?: string;
}

export interface II18nContextValue {
  activeLanguage: LocaleISO;
  changeLanguage: (activeLanguage: II18nContextValue['activeLanguage']) => void;

  //translations: ITranslationsMap;
  //setTranslations: (translations: II18nContextValue['translations']) => void;
  isExternalTranslationsLoading: boolean;
  i18n: I18nFn;
  isLoading: boolean;
}

type IKeyTranslationsMap = any;

export interface ITranslationsMap extends Partial<Record<LocaleISO, IKeyTranslationsMap>> {}

export const I18nContext = React.createContext<II18nContextValue>({
  activeLanguage: DEFAULT_LOCALE,
  changeLanguage: () => {},

  // translations: {},
  // setTranslations: () => {},
  isExternalTranslationsLoading: false,
  i18n: () => '',
  isLoading: true,
});

const I18nProvider: React.FC<IProps> = props => {
  // Get initial value of active locale
  // It's gonna be stored in localStorage value or default one
  const initialLocale = useMemo((): LocaleISO => {
    let result: LocaleISO = DEFAULT_LOCALE;

    const storedLocale = getFromLocalStorage('locale');
    if (storedLocale && typeof (storedLocale as unknown) === 'string') {
      return storedLocale as LocaleISO;
    }

    return result;
  }, []);

  const client = useApolloClient();

  const initialTranslations = useMemo((): Resource => {
    const translations: Resource = LOCAL_TRANSLATIONS;

    const storedTranslations = getFromLocalStorage('translations');

    if (storedTranslations && typeof (storedTranslations as unknown) !== 'string') {
      if (!translations) {
        return storedTranslations;
      }
      const payload: Resource = Object.keys(storedTranslations).reduce((accumulator: Resource, key) => {
        if (!translations[key] || !storedTranslations[key]) {
          return accumulator;
        }
        const localTranslation = translations[key].translation;

        const storedTranslation = storedTranslations[key].translation;
        accumulator[key] = { translation: Object.assign(localTranslation, storedTranslation) };
        return accumulator;
      }, {});

      return payload;
    }

    return translations;
  }, []);

  const [activeLanguage, setActiveLanguage] = useState<II18nContextValue['activeLanguage']>(initialLocale);
  const [serverUpdatedAt, setServerUpdatedAt] = useState<number | null>(null);
  const [translations, setTranslations] = useState<Resource>(initialTranslations);
  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const getLocalUpdatedAt = useCallback(activeLanguage => {
    const key = activeLanguage === 'ru' ? `translationsUpdatedAt_ru` : 'translationsUpdatedAt_en';
    return key;
  }, []);

  const initialUpdatedAt = useMemo(() => {
    const storedUpdatedAtRu = getFromLocalStorage(getLocalUpdatedAt('ru'));
    const storedUpdatedAtEn = getFromLocalStorage(getLocalUpdatedAt('en'));
    if (storedUpdatedAtRu || storedUpdatedAtEn) {
      return {
        en: storedUpdatedAtEn ? new Date(storedUpdatedAtEn).getTime() : null,
        ru: storedUpdatedAtRu ? new Date(storedUpdatedAtRu).getTime() : null,
      };
    }
    return { en: null, ru: null };
  }, [getLocalUpdatedAt]);

  const [updatedAt, setUpdatedAt] = useState<Record<LocaleISO, number | null>>(initialUpdatedAt);
  const [isInitialized, setIsInitialized] = useState(false);

  const initI18n = useCallback(async (translations, lang) => {
    if (!i18n || !i18n.isInitialized) {
      try {
        await i18n
          .use(initReactI18next) // passes i18n down to react-i18next
          .init({
            resources: translations,
            lng: lang,

            keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
              escapeValue: false, // react already safes from xss
            },
          });
      } catch (err) {}
      setIsInitialized(true);
    }
  }, []);

  const updateTranslationsUpdatedAt = useCallback(
    (serverUpdatedDate: number, activeLanguage: LocaleISO) => {
      writeToLocalStorage(getLocalUpdatedAt(activeLanguage), serverUpdatedDate);
      setUpdatedAt({ ...updatedAt, [activeLanguage]: serverUpdatedDate });
    },
    [getLocalUpdatedAt, updatedAt]
  );

  const handleFetchSuccess = useCallback(
    async (data: AllWebTranslationsQuery) => {
      const translationsArr = data?.allWebTranslations.edges ?? [];
      // Create and fill map
      const translationsMap: IKeyTranslationsMap = {};

      for (const translation of translationsArr) {
        translationsMap[translation.key] = translation.value;
      }

      const payload: Resource = {
        ...translations,
        [activeLanguage]: {
          translation: Object.assign(LOCAL_TRANSLATIONS[activeLanguage].translation, translationsMap),
        },
      };
      if (i18n && i18n.isInitialized) {
        i18n.addResources(activeLanguage, 'translation', payload[activeLanguage].translation);
        i18n.changeLanguage(activeLanguage);
      } else {
        initI18n(payload, activeLanguage);
      }
      writeToLocalStorage('translations', payload);
      setTranslations(payload);
      if (serverUpdatedAt) {
        updateTranslationsUpdatedAt(serverUpdatedAt, activeLanguage);
      }
    },
    [translations, activeLanguage, serverUpdatedAt, initI18n, updateTranslationsUpdatedAt]
  );

  const [getAllWebTranslations, getAllWebTranslationsResult] = useAllWebTranslationsLazyQuery({
    fetchPolicy: 'network-only',

    onCompleted: handleFetchSuccess,
  });

  const rehydrateTranslations = useCallback(() => {
    getAllWebTranslations({
      variables: {
        locale: activeLanguage,
      },
    });
  }, [getAllWebTranslations, activeLanguage]);

  // start of getting translations
  const webUpdatedAtResult = useWebTranslationsUpdatedAtQuery({
    fetchPolicy: 'network-only',
    variables: {
      locale: activeLanguage || 'ru',
    },
    onCompleted: result => {
      const stringUpdatedAt = result.webTranslationsUpdatedAt || null;
      const serverUpdatedAt = stringUpdatedAt ? new Date(stringUpdatedAt).getTime() : null;
      const currentUpdatedAt = updatedAt[activeLanguage];
      const localUpdatedAt = currentUpdatedAt ? new Date(currentUpdatedAt).getTime() : null;
      setServerUpdatedAt(serverUpdatedAt);
      // rehydrate local storage with external local translations
      if (!localUpdatedAt || serverUpdatedAt !== localUpdatedAt) {
        rehydrateTranslations();
        return;
      }
      if (i18n && i18n.isInitialized) {
        setIsInitialized(true);
        i18n.changeLanguage(activeLanguage);
        return;
      }
    },
  });

  const changeLanguage = useCallback(
    (locale: LocaleISO) => {
      writeToLocalStorage('locale', locale, true);
      setActiveLanguage(locale);
      setIsChangingLanguage(true);
      client.cache.reset().finally(() => setIsChangingLanguage(false));
    },
    [client]
  );

  useEffect(() => {
    if (!i18n || !i18n.isInitialized) {
      initI18n(initialTranslations, activeLanguage);
    }
  }, [activeLanguage, initI18n, initialTranslations]);

  const isLoading = !isInitialized;
  const isExternalTranslationsLoading =
    webUpdatedAtResult.loading || getAllWebTranslationsResult.loading || isChangingLanguage;
  const ctx = useMemo(
    () => ({ isExternalTranslationsLoading, isLoading, activeLanguage, changeLanguage, i18n: (() => i18n.t)() }),
    [activeLanguage, changeLanguage, isExternalTranslationsLoading, isLoading]
  );

  return <I18nContext.Provider value={ctx}>{isInitialized ? props.children : null}</I18nContext.Provider>;
};

export default React.memo(I18nProvider);
