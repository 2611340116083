import { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
interface IProps {}

const ScrollToTop: React.FC<IProps> = props => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (document?.body?.scrollTop) {
        document.body.scrollTop = 0;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default memo(ScrollToTop);
