export const LOCAL_TRANSLATIONS = {
  en: {
    translation: {
      hello: 'Hello',
      h_page_not_found: 'Page not found',
      route_templates: 'TEMPLATES',
      route_library: 'LIBRARY',
      route_users: 'USERS',
      users: 'Users',
      roles: 'Roles',
      user: 'User',
      email: 'Email',
      role: 'Role',
      d_password_reset_success: `We have sent you a magic link. Check your
      email and try to sign in.`,
      t_password_reset_success: 'Password restored!',
      a_ok: 'OK',
      a_edit_profile: 'Edit profile',
      a_sign_out: 'Sign out',
      l_add_file: 'drag and drop file here or open Finder',
      t_home_1: `A wonderful app for
			creating media materials
			for your brand`,
      d_home_1: `Create a Library of materials you use to create media for your socials and use mobile app to build them fast`,
      t_home_2: 'How does it work',
      a_submit: 'Submit',
      a_register_not_got_email: 'Have not got email?',
      a_register_use_anotherEmail: 'Use another email',
      route_about: 'About',
      route_apps: 'Apps',
      route_how_it_works: 'How it works',
      route_reviews: 'Reviews',
      route_for_whom: 'For whom',
      route_clients: 'Clients',
    },
  },

  ru: {
    translation: {
      hello: 'Привет',
      h_page_not_found: 'Страницы нетути....',
    },
  },
};
