import { ApolloError, FetchResult } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AuthContext } from '../components/AuthProvider/AuthProvider';
import { PRIVATE_ENTRY_PAGE } from '../lib/constants';
import { LoginInput, LoginMutation, useLoginMutation } from '../types/graphql';
import { TUseForgotPasswordForm } from './useForgotPasswordForm';
import useModal from './useModal';

export type TUseLoginUserForm = {
  isModalOpened: boolean;
  openModal: () => void;
  onSubmit: (values: LoginInput) => void;
  onPasswordReset: () => void;
  closeModal: () => void;
  error?: ApolloError | null;
  isLoading: boolean;
  handleLogin: () => void;
  loginButtonLabel: string;
};

function useLoginUserForm(forgotPasswodForm?: TUseForgotPasswordForm, isInitialOpen?: boolean): TUseLoginUserForm {
  const { isModalOpened, closeModal, openModal } = useModal();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);

  const [login, loginResult] = useLoginMutation();

  const isLoading = loginResult.loading;
  const error = loginResult.error;

  useEffect(() => {
    if (isInitialOpen) {
      openModal();
    }
  }, [isInitialOpen, openModal]);

  const handleLoginSuccess = useCallback(
    (response: FetchResult<LoginMutation>) => {
      const userAuthResponse = response?.data?.login;
      if (!userAuthResponse) {
        throw new Error(t('auth_error'));
      }

      authContext.login(userAuthResponse, () => {
        history.push(PRIVATE_ENTRY_PAGE);
      });
    },
    [authContext, history, t]
  );

  const loginButtonLabel = useMemo(() => (profile ? 'a_go_to_site' : 'a_login'), [profile]);

  const handleResetPasswordClick = useCallback(() => {
    forgotPasswodForm?.openModal();
    closeModal();
  }, [closeModal, forgotPasswodForm]);

  const handleLoginError = useCallback(() => {}, []);

  const handleSubmit = useCallback(
    (values: LoginInput) => {
      login({ variables: { data: values } })
        .then(handleLoginSuccess)
        .catch(handleLoginError);
    },
    [handleLoginError, handleLoginSuccess, login]
  );

  const handleLogin = useCallback(() => {
    if (profile) {
      history.push(PRIVATE_ENTRY_PAGE);
      return;
    }
    openModal();
  }, [history, openModal, profile]);

  const ctx = useMemo(
    () => ({
      isModalOpened,
      openModal,
      onSubmit: handleSubmit,
      closeModal,
      isLoading,
      error,
      onPasswordReset: handleResetPasswordClick,
      handleLogin,
      loginButtonLabel,
    }),
    [
      isModalOpened,
      openModal,
      handleSubmit,
      closeModal,
      isLoading,
      error,
      handleResetPasswordClick,
      handleLogin,
      loginButtonLabel,
    ]
  );

  return ctx;
}

export default useLoginUserForm;
