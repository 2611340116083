import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 1.1rem;
  opacity: 0.48;
`;

interface IProps {
  className?: string;
}

const Desctiption: React.FC<React.PropsWithChildren<IProps>> = props => {
  const { className, children } = props;
  return <Container className={className}>{children}</Container>;
};

export default React.memo(Desctiption);
