import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  GET_ANIMATION_DURATION,
  GET_ANIMATION_EASING,
  GET_BOX_SHADOW_3,
  GET_COLOR_MAIN_2,
  GET_COLOR_MAIN_3,
} from '../../lib/themeGetters';
import { TIconProps } from '../../types/local';

interface IProps extends TIconProps {
  onClick?: () => void;
  color?: string;
}

const BurgerIconContainer = styled.div<{ width?: number; height?: number }>`
  width: ${p => p.width || 32}px;
  height: ${p => p.height || 32}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${GET_COLOR_MAIN_2};
  border-radius: 50%;

  transition: background-color ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING},
    box-shadow ${GET_ANIMATION_DURATION} ${GET_ANIMATION_EASING};
  cursor: pointer;
  :hover {
    background-color: ${GET_COLOR_MAIN_3};
    box-shadow: ${GET_BOX_SHADOW_3};
  }
`;

const BurgerIcon: React.FC<IProps> = props => {
  const { width = 24, height = 24, onClick, isWrapped, opacity } = props;
  const theme = useTheme();
  const color = props.color || theme.colors.secondary1;
  const content = useMemo(
    () => (
      <svg
        onClick={onClick}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        opacity={opacity}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 6H20V8H4V6ZM4 11H20V13H4V11ZM20 16H4V18H20V16Z"
          fill={color}
          opacity={opacity}
        />
      </svg>
    ),
    [color, onClick, opacity, width]
  );
  return isWrapped ? (
    <BurgerIconContainer width={Math.round(width * 1.333)} height={Math.round(height * 1.333)} onClick={onClick}>
      {content}
    </BurgerIconContainer>
  ) : (
    content
  );
};

export default React.memo(BurgerIcon);
