export function parseJSON<TResult = any>(json: string, logError = false): null | TResult {
  let result = null;

  try {
    result = JSON.parse(json);
  } catch (e) {
    if (logError) {
      console.error(e);
    }
  }

  return result as TResult;
}
