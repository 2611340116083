import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  GET_COLOR_CONTRAST_VIBRANT_1,
  GET_COLOR_DANGER_4,
  GET_COLOR_SECONDARY_1,
  GET_FONT_FAMILY_OPEN_SANS_REGULAR,
} from '../../lib/themeGetters';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import useEntityTimer from '../../hooks/useEntityTimer';
import { differenceInSeconds, intervalToDuration } from 'date-fns';
import { AuthContext } from '../AuthProvider/AuthProvider';
import { UserUserTierState } from '../../types/graphql';
import { useHistory } from 'react-router-dom';
import { getDaysLocale } from '../../lib/localeHelpers';

const Container = styled.div`
  background-color: ${GET_COLOR_SECONDARY_1};
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  margin-top: 28px;
  max-width: 1289px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${p => p.theme.breakpoints.xl}px) {
    width: calc(100% - 50px);
  }
  @media (max-width: ${p => p.theme.breakpoints.sm}px) {
    width: calc(100% - 50px);
    flex-direction: column;
  }
`;

const TrialTime = styled.p<{ isExpired?: boolean }>`
  margin-bottom: 0;
  font-size: 22px;
  line-height: 30px;
  font-family: ${GET_FONT_FAMILY_OPEN_SANS_REGULAR};
  ${p => (p.isExpired ? `color: ${GET_COLOR_DANGER_4(p)}` : `color: ${GET_COLOR_CONTRAST_VIBRANT_1(p)}`)};
  ${p => (p.isExpired ? `font-weight: bold` : `font-weight: bold`)};
`;

const TrialInfo = styled.p<{ isExpired?: boolean }>`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 19px;
  font-family: ${GET_FONT_FAMILY_OPEN_SANS_REGULAR};
  opacity: 0.67;
  ${p => (p.isExpired ? `color: ${GET_COLOR_DANGER_4(p)}` : `color: ${GET_COLOR_CONTRAST_VIBRANT_1(p)}`)};
`;

interface IProps {}

const SubscriptionBanner: React.FC<IProps> = props => {
  const { t } = useTranslation();
  const { profile } = useContext(AuthContext);
  const history = useHistory();

  const expirationDateInSeconds = differenceInSeconds(new Date(profile?.tier?.expiredAt), new Date());
  const timer = useEntityTimer('subscriptionTrialTimer', expirationDateInSeconds);

  const runTimer = timer.runTimer;

  useEffect(() => {
    runTimer();
  }, [runTimer]);

  const isSubscriptionActive = profile?.tier?.isTrial === false && profile?.tier?.state === UserUserTierState.ACTIVE;
  const isTrialExpired = profile?.tier?.isTrial === true && profile?.tier?.state === UserUserTierState.EXPIRED;
  const isSubscriptionExpited = profile?.tier?.isTrial === false && profile?.tier?.state === UserUserTierState.EXPIRED;

  const renderInfo = React.useCallback(() => {
    const { days, hours, minutes, seconds } = intervalToDuration({ start: new Date(profile?.tier?.expiredAt), end: new Date() });
    let message = ''
    if (days) {
      message = `${days} ${t(getDaysLocale(days))}`;
    } else {
      message = `${('00'+hours).slice(-2)}:${('00'+minutes).slice(-2)}:${('00'+seconds).slice(-2)}`
    }
    return (
      <>
        <TrialTime>{`${t('trial_ends_in_days')} ${message}`}</TrialTime>
        <TrialInfo>{t('trial_info')}</TrialInfo>
      </>
    );
  }, [profile?.tier?.expiredAt, t]);

  const handleSubscriptionNavigation = React.useCallback(() => {
    if (isSubscriptionExpited) {
      history.push('/publisher-check-subscription-instructions');
    }
    history.push('/publisher-subscription-instructions');
  }, [history, isSubscriptionExpited]);

  const renderButton = React.useCallback(() => {
    const label = isSubscriptionExpited ? t('a_check_subscribtion') : t('a_subscribe_now');
    return (
      <Button onClick={handleSubscriptionNavigation} color="main" type="submit">
        {label}
      </Button>
    );
  }, [handleSubscriptionNavigation, isSubscriptionExpited, t]);

  const renderExpiredSubscription = React.useCallback(() => {
    return (
      <Container>
        <div>
          <TrialTime>{`${t('subscription_expired')}`}</TrialTime>
          <TrialInfo>{t('fill_your_bank_account')}</TrialInfo>
        </div>
        <div>{renderButton()}</div>
      </Container>
    );
  }, [renderButton, t]);

  const renderTrialExpired = React.useCallback(() => {
    return (
      <Container>
        <div>
          <TrialTime>{`${t('trial_period_ended')}`}</TrialTime>
          <TrialInfo>{t('trial_info')}</TrialInfo>
        </div>
        <div>{renderButton()}</div>
      </Container>
    );
  }, [renderButton, t]);

  const renderEssence = React.useCallback(() => {
    if (isSubscriptionActive) {
      return null;
    }
    if (isTrialExpired) {
      return renderTrialExpired();
    }
    if (isSubscriptionExpited) {
      return renderExpiredSubscription();
    }

    return (
      <Container>
        <div>{renderInfo()}</div>
        <div>{renderButton()}</div>
      </Container>
    );
  }, [
    isSubscriptionActive,
    isSubscriptionExpited,
    isTrialExpired,
    renderButton,
    renderExpiredSubscription,
    renderInfo,
    renderTrialExpired,
  ]);

  if (!profile || !profile.tier) {
    return null;
  }

  return renderEssence();
};

export default React.memo(SubscriptionBanner);
