import React from 'react';
import * as Bootstrap from 'reactstrap';
import styled from 'styled-components';

const StyledCol = styled(Bootstrap.Col)<{ $noGutters?: boolean }>`
  ${p => (p.$noGutters ? 'padding-right: 0;padding-left: 0;' : '')}
`;

export interface IColProps extends Bootstrap.ColProps {}

const Col: React.FC<IColProps> = props => {
  const { noGutters, ...otherProps } = props;
  return <StyledCol $noGutters={noGutters} {...otherProps} />;
};

export default Col;
