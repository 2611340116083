export function getDaysLocale(n: number) {
  let d = n > 10 ? Number(`${n}`.slice(-2)) : Number(`${n}`.slice(-1))
  if (d > 10 && d < 15) {
    return 'days'
  }
  d = Number(`${n}`.slice(-1))
  if (d === 1) {
    return 'day'
  }
  if (d > 1 && d < 5) {
    return 'days_v'
  }
  return `days`
}