import { useCallback, useMemo, useState } from 'react';
import { TUseModalResult } from '../types/local';

function useModal(): TUseModalResult {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  const ctx = useMemo(() => ({ openModal, closeModal, isModalOpened }), [closeModal, openModal, isModalOpened]);
  return ctx;
}

export default useModal;
