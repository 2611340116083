import { useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export type NotifyFn = (message: string, options?: ToastOptions) => void;

export const COMMON_NOTIFICATION_CONFIG: Omit<ToastOptions, 'type'> = {
  hideProgressBar: true,
  autoClose: 1500,
};

export const useNotify = (): NotifyFn => {
  return useCallback((message, options) => {
    toast(message, {
      ...COMMON_NOTIFICATION_CONFIG,
      ...options,
    });
  }, []);
};
