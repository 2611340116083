import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GET_FONT_WEIGHT_SEMI_BOLD } from '../../lib/themeGetters';
import Button from '../Button/Button';
import OkIcon from '../OkIcon/OkIcon';
import Title from '../Title/Title';

interface IProps {
  onSubmit: () => void;
}

const Description = styled.div`
  font-size: 18px;
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
  margin-top: 2rem;
`;

const ResetPasswordSuccess: React.FC<IProps> = props => {
  const { t } = useTranslation();

  return (
    <div className="text-break">
      <div className="d-flex align-items-center">
        <OkIcon />
        <Title className="ml-3">{t('t_password_reset_success')}</Title>
      </div>
      <Description>{t('d_password_reset_success')}</Description>
      <div className="d-flex justify-content-end mt-4">
        <Button onClick={props.onSubmit} color="secondary">
          {t('a_ok')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ResetPasswordSuccess);
