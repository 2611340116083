import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PROJECT_NAME } from '../lib/constants';

function useDocumentTitle(title?: string | string[], withoutTranslation?: boolean) {
  const { t } = useTranslation();

  const translate = useMemo(() => (withoutTranslation ? (text: string) => text : t), [t, withoutTranslation]);

  useEffect(() => {
    if (!document || !document.title) {
      return;
    }
    if (!title) {
      document.title = `${t(`title_${PROJECT_NAME}`)}`;
      return;
    }
    if (typeof title === 'string') {
      document.title = `${translate(title)} — ${t(`title_${PROJECT_NAME}`)}`;
    } else if (typeof title === 'object' && Array.isArray(title)) {
      const text = title.reduce((result: string, key: string, index: number) => {
        const isFirst = index === 0;
        result += `${isFirst ? '' : '-'} ${translate(key)} `;
        return result;
      }, '');
      document.title = `${text} — ${t(`title_${PROJECT_NAME}`)}`;
      return;
    }

    return () => {
      document.title = `${t(`title_${PROJECT_NAME}`)}`;
    };
  }, [t, title, translate]);

  return;
}

export default useDocumentTitle;
