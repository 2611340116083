import React from 'react';
import { UserTeamFragment, UserTeamMemberProfileFragment, UserTeamMemberSmallFragment } from '../types/graphql';
import { TUseModalResult } from '../types/local';

interface ITeamContext {
  team?: UserTeamFragment;
  teamMembersip?: UserTeamMemberProfileFragment[];
  teamMember?: UserTeamMemberProfileFragment | null;
  teamMembers?: UserTeamMemberProfileFragment | null;
  switchTeam: (team: UserTeamFragment) => void;
  teamOwner?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  isMeTeamOwner?: boolean;
  isMeAdmin?: boolean;
  canInviteUsers?: boolean;
  canDeleteUsers?: boolean;
  canDeleteLibraryItem?: boolean;
  canCreateLibraryItem?: boolean;
  canEditLibraryItem?: boolean;
  canEditTemplate?: boolean;
  canCreateTemplate?: boolean;
  canDeleteTemplate?: boolean;
  canEditLibraryCategory?: boolean;
  canCreateLibraryCategory?: boolean;
  canCreateTemplateCategory?: boolean;
  canEditTemplateCategory?: boolean;
  canDeleteTemplateCategory?: boolean;
}

export const TeamContext = React.createContext<ITeamContext>({
  switchTeam: () => {},
});

export interface ITeamMemberModalContext extends Omit<TUseModalResult, 'openModal'> {
  openModal: (teamMember?: UserTeamMemberSmallFragment) => void;
  teamMember: UserTeamMemberSmallFragment | null;
}

export const TeamMemberModalContext = React.createContext<ITeamMemberModalContext>({
  openModal: () => {},
  closeModal: () => {},
  isModalOpened: false,
  teamMember: null,
});
