import { DefaultTheme, ThemedStyledProps } from 'styled-components';

export type StyledFnProps<TComponentProps extends {} = {}> = ThemedStyledProps<TComponentProps, DefaultTheme>;

// Folder getters
const getColors = (p: StyledFnProps) => p.theme.colors;
const getFontWeights = (p: StyledFnProps) => p.theme.fontWeights;
const getAnimations = (p: StyledFnProps) => p.theme.animations;
const getFontSizes = (p: StyledFnProps) => p.theme.fontSizes;
const getMargins = (p: StyledFnProps) => p.theme.margins;
const getPaddings = (p: StyledFnProps) => p.theme.paddings;
const getRandomColors = (p: StyledFnProps) => p.theme.randomColors;
const getBreakpoints = (p: StyledFnProps) => p.theme.breakpoints;
const getShadows = (p: StyledFnProps) => p.theme.shadows;
const getOther = (p: StyledFnProps) => p.theme.other;
const getGradients = (p: StyledFnProps) => p.theme.gradients;
const getText = (p: StyledFnProps) => p.theme.text;
const getName = (p: StyledFnProps) => p.theme.name;
const getSeparator = (p: StyledFnProps) => p.theme.separator;
const getBackgroundColor = (p: StyledFnProps) => p.theme.background;
const getLodgeColor = (p: StyledFnProps) => p.theme.lodgeColor;
const getFontFamilies = (p: StyledFnProps) => p.theme.fontFamilies;

// I made this type for type safety
// to be sure if we change theme structure
// we will be forced to update getters in this file
type FolderGetterMap = {
  [TFolder in keyof DefaultTheme]: (p: StyledFnProps) => DefaultTheme[TFolder];
};

const getTheme: FolderGetterMap = {
  animations: getAnimations,
  colors: getColors,
  fontWeights: getFontWeights,
  fontSizes: getFontSizes,
  margins: getMargins,
  paddings: getPaddings,
  randomColors: getRandomColors,
  breakpoints: getBreakpoints,
  shadows: getShadows,
  other: getOther,
  gradients: getGradients,
  text: getText,
  name: getName,
  separator: getSeparator,
  background: getBackgroundColor,
  lodgeColor: getLodgeColor,
  fontFamilies: getFontFamilies,
};

/**
 * COLORS
 */

// Main colors
export const GET_COLOR_MAIN_1 = (p: StyledFnProps) => getColors(p).main1;
export const GET_COLOR_MAIN_2 = (p: StyledFnProps) => getColors(p).main2;
export const GET_COLOR_MAIN_3 = (p: StyledFnProps) => getColors(p).main3;

// Contrast colors
export const GET_COLOR_CONTRAST_1 = (p: StyledFnProps) => getColors(p).contrast1;
export const GET_COLOR_CONTRAST_2 = (p: StyledFnProps) => getColors(p).contrast2;
export const GET_COLOR_CONTRAST_3 = (p: StyledFnProps) => getColors(p).contrast3;
export const GET_COLOR_CONTRAST_4 = (p: StyledFnProps) => getColors(p).contrast4;

// Vibrant contrasts colors
export const GET_COLOR_CONTRAST_VIBRANT_1 = (p: StyledFnProps) => getColors(p).contrastVibrant1;
export const GET_COLOR_CONTRAST_VIBRANT_2 = (p: StyledFnProps) => getColors(p).contrastVibrant2;
export const GET_COLOR_CONTRAST_VIBRANT_3 = (p: StyledFnProps) => getColors(p).contrastVibrant3;

// Info colors
export const GET_COLOR_INFO_1 = (p: StyledFnProps) => getColors(p).info1;
export const GET_COLOR_INFO_2 = (p: StyledFnProps) => getColors(p).info2;
export const GET_COLOR_INFO_3 = (p: StyledFnProps) => getColors(p).info3;

// Primary colors
export const GET_COLOR_PRIMARY_1 = (p: StyledFnProps) => getColors(p).primary1;
export const GET_COLOR_PRIMARY_2 = (p: StyledFnProps) => getColors(p).primary2;
export const GET_COLOR_PRIMARY_3 = (p: StyledFnProps) => getColors(p).primary3;

// Secondary colors
export const GET_COLOR_SECONDARY_1 = (p: StyledFnProps) => getColors(p).secondary1;
export const GET_COLOR_SECONDARY_2 = (p: StyledFnProps) => getColors(p).secondary2;
export const GET_COLOR_SECONDARY_3 = (p: StyledFnProps) => getColors(p).secondary3;

// Additional colors
export const GET_COLOR_DANGER_1 = (p: StyledFnProps) => getColors(p).danger1;
export const GET_COLOR_DANGER_2 = (p: StyledFnProps) => getColors(p).danger1;
export const GET_COLOR_DANGER_3 = (p: StyledFnProps) => getColors(p).danger1;
export const GET_COLOR_DANGER_4 = (p: StyledFnProps) => getColors(p).danger4;

export const GET_COLOR_SUCCESS_1 = (p: StyledFnProps) => getColors(p).success1;
export const GET_COLOR_SUCCESS_2 = (p: StyledFnProps) => getColors(p).success1;
export const GET_COLOR_SUCCESS_3 = (p: StyledFnProps) => getColors(p).success1;

export const GET_COLOR_WARNING_1 = (p: StyledFnProps) => getColors(p).warning1;
export const GET_COLOR_WARNING_2 = (p: StyledFnProps) => getColors(p).warning1;
export const GET_COLOR_WARNING_3 = (p: StyledFnProps) => getColors(p).warning1;

// Stuff
export const GET_COLOR_PARKING_WIDGET = (p: StyledFnProps) => getColors(p).parkingWidgetBackground;

/**
 * FONT WEIGHTS
 */
export const GET_FONT_WEIGHT_EXTRA_BOLD = (p: StyledFnProps) => getFontWeights(p).extraBold;
export const GET_FONT_WEIGHT_BOLD = (p: StyledFnProps) => getFontWeights(p).bold;
export const GET_FONT_WEIGHT_SEMI_BOLD = (p: StyledFnProps) => getFontWeights(p).semiBold;
export const GET_FONT_WEIGHT_NORMAL = (p: StyledFnProps) => getFontWeights(p).normal;
export const GET_FONT_WEIGHT_LIGHT = (p: StyledFnProps) => getFontWeights(p).light;

/**
 * ANIMATIONS
 */
export const GET_ANIMATION_EASING = (p: StyledFnProps) => getAnimations(p).easing;
export const GET_ANIMATION_DURATION = (p: StyledFnProps) => getAnimations(p).duration;

/**
 * PADDINGS
 */
export const GET_PADDING_1 = (p: StyledFnProps) => getPaddings(p).padding1;
export const GET_PADDING_2 = (p: StyledFnProps) => getPaddings(p).padding2;
export const GET_PADDING_3 = (p: StyledFnProps) => getPaddings(p).padding3;
export const GET_PADDING_4 = (p: StyledFnProps) => getPaddings(p).padding4;
export const GET_PADDING_5 = (p: StyledFnProps) => getPaddings(p).padding5;

/**
 * MARGINS
 */
export const GET_MARGIN_1 = (p: StyledFnProps) => getMargins(p).margin1;
export const GET_MARGIN_2 = (p: StyledFnProps) => getMargins(p).margin2;
export const GET_MARGIN_3 = (p: StyledFnProps) => getMargins(p).margin3;
export const GET_MARGIN_4 = (p: StyledFnProps) => getMargins(p).margin4;
export const GET_MARGIN_5 = (p: StyledFnProps) => getMargins(p).margin5;

/**
 * TEXT PROPERTIES
 */
export const GET_TEXT_FONT_BASE = (p: StyledFnProps) => getText(p).fontBase;
export const GET_TEXT_FONT_FAMILY = (p: StyledFnProps) => getText(p).fontFamily;
export const GET_TEXT_LINE_HEIGHT = (p: StyledFnProps) => getText(p).lineHeight;

/**
 * SHADOW
 */
export const GET_BOX_SHADOW_1 = (p: StyledFnProps) => getShadows(p).shadow1;
export const GET_BOX_SHADOW_2 = (p: StyledFnProps) => getShadows(p).shadow2;
export const GET_BOX_SHADOW_3 = (p: StyledFnProps) => getShadows(p).shadow3;
export const GET_BOX_DANGER_SHADOW_1 = (p: StyledFnProps) => getShadows(p).danger1;

/**
 * OTHER
 */
export const GET_BORDER_RADIUS_1 = (p: StyledFnProps) => getOther(p).borderRadius1;
export const GET_BORDER_RADIUS_2 = (p: StyledFnProps) => getOther(p).borderRadius2;
export const GET_BORDER_RADIUS_3 = (p: StyledFnProps) => getOther(p).borderRadius3;
export const GET_BORDER_RADIUS_4 = (p: StyledFnProps) => getOther(p).borderRadius4;

export const GET_INTERACTIONS_OPACITY = (p: StyledFnProps) => getOther(p).interactionOpacity;
export const GET_INTERACTIONS_OPACITY_2 = (p: StyledFnProps) => getOther(p).interactionOpacity2;

export const GET_SEPARATOR_COLOR_MAIN = (p: StyledFnProps) => getSeparator(p).colors.main1;

export const GET_BACKGROUND_PARKING_TICKET = (p: StyledFnProps) => getBackgroundColor(p).parkingTicket;

export const GET_BACKGROUND_MATCH_TICKET = (p: StyledFnProps) => getBackgroundColor(p).matchTicket;

export const GET_ACTIVE_LODGE = (p: StyledFnProps) => getLodgeColor(p).active;
// export const GET_INACTIVE_LODGE = (p: StyledFnProps) => getLodgeColor(p).inactive;
export const GET_SECTOR_SALE_LODGE = (p: StyledFnProps) => getLodgeColor(p).sectorSale;

export const GET_INACTIVE_LODGE = (p: StyledFnProps) => getLodgeColor(p).inactive;

export const GET_FONT_FAMILY_SF_REGULAR = (p: StyledFnProps) => getFontFamilies(p).sfPro.regular;
export const GET_FONT_FAMILY_SF_BOLD = (p: StyledFnProps) => getFontFamilies(p).sfPro.bold;

export const GET_FONT_FAMILY_OPEN_SANS_REGULAR = (p: StyledFnProps) => getFontFamilies(p).openSans.regular;
export const GET_FONT_FAMILY_OPEN_SANS_MEDIUM = (p: StyledFnProps) => getTheme.fontFamilies(p).openSans.medium;
