import { Resource } from 'i18next';
import { ThemeNames } from '../components/ThemeProvider/ThemeProvider';
import { LocalStorageNotFoundError } from '../errors';
import { UserFragment } from '../types/graphql';
import { LocaleISO } from '../types/local';
import { IS_BROWSER } from './constants';
import { parseJSON } from './parseJSON';

export interface LocalStorageMap extends LocalStorageTimerMap, LocalStorageSwitchMap {
  accessToken: string;
  refreshToken: string;
  locale: LocaleISO;
  translations: Resource;
  themeName: ThemeNames;
  translationsUpdatedAt_en: number;
  translationsUpdatedAt_ru: number;
  user: UserFragment;
  teamId: number;
  invite_key: string;
}

export interface LocalStorageTimerMap {
  resetPasswordTimer?: number;
  subscriptionTrialTimer?: number;
}

export interface LocalStorageSwitchMap {
  templatesSwitchIndex?: number;
  templatesGroupSwitchIndex?: number;
}

export type LocalStorageKeys = keyof LocalStorageMap;

const storageProxyContainer: Record<string, any> = {};

export const getFromLocalStorage = <TKey extends LocalStorageKeys>(key: TKey): null | LocalStorageMap[TKey] => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    return null;
  }
  if (key in storageProxyContainer) {
    return storageProxyContainer[key];
  }
  return getFromStorage(key);
};

export const getFromStorage = <TKey extends LocalStorageKeys>(key: TKey): null | LocalStorageMap[TKey] => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    return null;
  }

  let result = window.localStorage.getItem(key);
  if (!result) {
    return null;
  }

  return parseJSON(result) as null | LocalStorageMap[TKey];
};

export const writeToLocalStorage = <TKey extends LocalStorageKeys>(
  key: TKey,
  data: string | LocalStorageMap[TKey],
  stringify = true
) => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    throw new LocalStorageNotFoundError();
  }

  let value = data as string;
  if (stringify) {
    value = JSON.stringify(data);
  }

  storageProxyContainer[key] = data;

  return window.localStorage.setItem(key, value);
};

export const removeFromLocalStorage = <TKey extends LocalStorageKeys>(key: TKey) => {
  if (!IS_BROWSER || !window || !window.localStorage) {
    throw new LocalStorageNotFoundError();
  }

  storageProxyContainer[key] = undefined;
  return window.localStorage.removeItem(key);
};
