import React from 'react';
import styled from 'styled-components';
import * as Bootstrap from 'reactstrap';

const StyledContainer = styled(Bootstrap.Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export interface IContainerProps extends Bootstrap.ContainerProps {}

const Container: React.FC<IContainerProps> = props => {
  return <StyledContainer {...props} />;
};

export default Container;
