import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { NavHashLink } from 'react-router-hash-link';
import styled, { useTheme } from 'styled-components';
import { ILayoutLink } from '../../hooks/useLayoutLinks';
import {
  GET_COLOR_CONTRAST_1,
  GET_COLOR_MAIN_2,
  GET_COLOR_PRIMARY_1,
  GET_FONT_WEIGHT_SEMI_BOLD,
  GET_PADDING_3,
  GET_PADDING_4,
} from '../../lib/themeGetters';
import BurgerIcon from '../BurgerIcon/BurgerIcon';
import CloseIcon from '../CloseIcon/CloseIcon';
import Col from '../Col/Col';
import Row from '../Row/Row';

interface IProps {
  className?: string;
  renderActions?: () => JSX.Element;
  links: ILayoutLink[];
}

const TRANSITION_TIME = 400;

const BurgerOverlay = styled.div<{ $isOpened?: boolean }>`
  position: fixed;
  z-index: 998;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: ${p => (p.$isOpened ? '0vw' : '-100vw')};
  background-color: ${p => (p.$isOpened ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
  width: 100vw;
  height: 100vh;
  transition: background-color ${TRANSITION_TIME}ms ease;
`;

const StyledBurgerContainer = styled.div<{ $isOpened?: boolean }>`
  position: fixed;
  width: 70%;
  overflow-y: auto;
  height: 100vh;
  @media (min-width: ${p => p.theme.breakpoints.sm}px) {
    width: 50%;
  }
  @media (min-width: ${p => p.theme.breakpoints.md}px) {
    width: 40%;
  }
  transition: right ${TRANSITION_TIME}ms ease;
  right: ${p => (p.$isOpened ? '16px' : '-200vw')};
`;

const BurgerBody = styled.div`
  background-color: ${GET_COLOR_MAIN_2};
  top: 16px;
  overflow-y: auto;
  width: 100%;
  border-radius: 16px;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;

const StyledBurgerContent = styled.div`
  z-index: 1501;
  flex: 1;
  display: flex;
  justify-self: center;
  flex-direction: column;
  margin-top: 0.5rem;
`;

const NavLabel = styled.span`
  font-size: 1.2em;
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
`;

const MenuItem = styled(NavHashLink)<{ $isLast?: boolean }>`
  display: block;
  color: ${GET_COLOR_CONTRAST_1};
  font-weight: ${GET_FONT_WEIGHT_SEMI_BOLD};
  &.active {
    color: ${GET_COLOR_PRIMARY_1};
  }
`;

const Header = styled(Row)`
  // padding: calc(${GET_PADDING_3} * 0.75) ${GET_PADDING_4} !important;
`;

const NavBurger: React.FC<IProps> = props => {
  const { className, links, renderActions } = props;
  const [opened, setOpened] = useState(false);

  const theme = useTheme();
  const parent = useMemo(() => document.getElementById('root'), []);

  const toggleNav = useCallback(() => {
    if (opened) {
      setTimeout(() => {
        document.body.className = '';
      }, 0);
    } else {
      document.body.className = 'overflow-hidden';
    }

    setOpened(!opened);
  }, [opened]);

  const renderCloseButton = useCallback(() => {
    const color = theme.colors.secondary1;
    return (
      <div className="d-flex align-items-start justify-content-end">
        <CloseIcon isWrapped strokeColor={color} onClick={toggleNav} className="cursor-pointer" />
      </div>
    );
  }, [theme.colors.secondary1, toggleNav]);

  const handleNavigation = useCallback(() => {
    toggleNav();
  }, [toggleNav]);

  const handleScroll = useCallback((el: HTMLElement) => {
    el.scrollIntoView({ behavior: 'auto', block: 'center' });
  }, []);

  const renderElement = useCallback(
    (item: ILayoutLink) => {
      const { text, link } = item;
      return (
        <MenuItem scroll={handleScroll} key={text} onClick={handleNavigation} className="px-5 my-3" to={link}>
          <NavLabel>{text}</NavLabel>
        </MenuItem>
      );
    },
    [handleNavigation, handleScroll]
  );

  const renderHeader = useCallback(
    () => (
      <Header className="mx-4">
        <Col className="d-flex justify-content-end" xs={12}>
          {renderCloseButton()}
        </Col>
      </Header>
    ),
    [renderCloseButton]
  );

  const child = useMemo(
    () => (
      <BurgerOverlay onClick={toggleNav} $isOpened={opened}>
        <StyledBurgerContainer $isOpened={opened}>
          <BurgerBody>
            {renderHeader()}
            {renderActions?.()}
            <StyledBurgerContent>{links.map(renderElement)}</StyledBurgerContent>
          </BurgerBody>
        </StyledBurgerContainer>
      </BurgerOverlay>
    ),
    [links, opened, renderActions, renderElement, renderHeader, toggleNav]
  );

  const el = useMemo(() => document && document.createElement('div'), []);

  const Portal = useMemo(() => (parent && el ? ReactDOM.createPortal(child, el) : null), [child, el, parent]);

  useEffect(() => {
    if (!parent || !el) {
      return;
    }
    parent.appendChild(el);

    return () => {
      if (!parent) {
        return;
      }

      parent.removeChild(el);
    };
  }, [el, parent]);

  return (
    <div className={className}>
      <BurgerIcon isWrapped width={28} height={28} onClick={toggleNav} />
      {Portal}
    </div>
  );
};

export default React.memo(NavBurger);
