import React from 'react';
import * as Bootstrap from 'reactstrap';
import styled from 'styled-components';

const StyledRow = styled(Bootstrap.Row)``;

export interface IRowProps extends Bootstrap.RowProps {}

const Row: React.FC<IRowProps> = props => {
  return <StyledRow {...props} />;
};

export default Row;
