import React from 'react';
import loadable from '@loadable/component';

import { BrowserRouter, Switch } from 'react-router-dom';
import AppRoute from '../AppRoute/AppRoute';
import TeamProvider from '../TeamProvider/TeamProvider';

import ScrollToTop from '../ScrollToTop/ScrollToTop';

const NotFoundPage = loadable(() => import(/* webpackPrefetch: true */ '../NotFoundPage/NotFoundPage'));
const HomePage = loadable(() => import(/* webpackPrefetch: true */ '../HomePage/HomePage'));
const UsersPage = loadable(() => import(/* webpackPrefetch: true */ '../UsersPage/UsersPage'));
const LibraryPage = loadable(() => import(/* webpackPrefetch: true */ '../LibraryPage/LibraryPage'));
const RoleEditPage = loadable(() => import(/* webpackPrefetch: true */ '../RoleEditPage/RoleEditPage'));
const UserEditPage = loadable(() => import(/* webpackPrefetch: true */ '../UserEditPage/UserEditPage'));
const SubscriptionPage = loadable(() => import(/* webpackPrefetch: true */ '../SubscriptionPage/SubscriptionPage'));
const TemplatesPage = loadable(() => import(/* webpackPrefetch: true */ '../TemplatesPage/TemplatesPage'));
const TemplatesGroupPage = loadable(
  () => import(/* webpackPrefetch: true */ '../TemplatesGroupPage/TemplatesGroupPage')
);
const DeclineInvite = loadable(() => import(/* webpackPrefetch: true */ '../DeclineInvite/DeclineInvite'));
const AcceptInvite = loadable(() => import(/* webpackPrefetch: true */ '../AcceptInvite/AcceptInvite'));
const VerifyEmail = loadable(() => import(/* webpackPrefetch: true */ '../VerifyEmail/VerifyEmail'));
const PrivacyPolicy = loadable(() => import(/* webpackPrefetch: true */ '../PrivacyPolicy/PrivacyPolicy'));
const TermsOfUse = loadable(() => import(/* webpackPrefetch: true */ '../TermsOfUse/TermsOfUse'));
const TemplateEditPage = loadable(() => import(/* webpackPrefetch: true */ '../TemplateEditPage/TemplateEditPage'));
const TextPresetEditPage = loadable(
  () => import(/* webpackPrefetch: true */ '../TextPresetEditPage/TextPresetEditPage')
);
const LibraryStickersCategoryPage = loadable(
  () => import(/* webpackPrefetch: true */ '../LibraryStickersCategoryPage/LibraryStickersCategoryPage')
);
const LibraryLogosCategoryPage = loadable(
  () => import(/* webpackPrefetch: true */ '../LibraryLogosCategoryPage/LibraryLogosCategoryPage')
);

const TransactionsPage = loadable(() => import(/* webpackPrefetch: true */ '../TransactionsPage/TransactionsPage'));

const ChangeSubscriptionPlanInstructionsPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '../ChangeSubscriptionPlanInstructionsPage/ChangeSubscriptionPlanInstructionsPage'
    )
);

const CheckSubscriptionInstructionsPage = loadable(
  () => import(/* webpackPrefetch: true */ '../CheckSubscriptionInstructionsPage/CheckSubscriptionInstructionsPage')
);

const PublisherSubscriptionInstructionsPage = loadable(
  () =>
    import(/* webpackPrefetch: true */ '../PublisherSubscriptionInstructionsPage/PublisherSubscriptionInstructionsPage')
);

interface IExternalProps {}

interface IProps extends IExternalProps {}

class Routes extends React.PureComponent<IProps> {
  render() {
    return (
      <BrowserRouter>
        <TeamProvider>
          <ScrollToTop />
          <Switch>
            <AppRoute exact path="/" component={HomePage} layout="public" />
            <AppRoute exact path="/users/" component={UsersPage} layout="private" />
            <AppRoute exact path="/users/edit/:id" component={UserEditPage} layout="private" />
            <AppRoute exact path="/users/roles/edit/:id" component={RoleEditPage} layout="private" />
            <AppRoute exact path="/library" component={LibraryPage} layout="private" />
            <AppRoute exact path="/library/text-presets/edit/:id" component={TextPresetEditPage} layout="private" />
            <AppRoute
              exact
              path="/library/stickers/:categoryId"
              component={LibraryStickersCategoryPage}
              layout="private"
            />
            <AppRoute exact path="/library/logos/:categoryId" component={LibraryLogosCategoryPage} layout="private" />
            <AppRoute exact path="/templates" component={TemplatesPage} layout="private" />
            <AppRoute
              exact
              path="/templates/change-subscription-plan-instructions"
              component={ChangeSubscriptionPlanInstructionsPage}
              layout="private"
            />

            <AppRoute exact path="/templates/edit/:id" component={TemplateEditPage} layout="private" />
            <AppRoute exact path="/templates/:id" component={TemplatesGroupPage} layout="private" />
            <AppRoute exact path="/subscription" component={SubscriptionPage} layout="private" />
            <AppRoute exact path="/transactions" component={TransactionsPage} layout="private" />

            <AppRoute
              exact
              path="/publisher-subscription-instructions"
              component={PublisherSubscriptionInstructionsPage}
              layout="private"
            />
            <AppRoute
              exact
              path="/publisher-check-subscription-instructions"
              component={CheckSubscriptionInstructionsPage}
              layout="private"
            />
            <AppRoute exact path="/invite-decline" component={DeclineInvite} layout="public" />
            <AppRoute exact path="/invite-accept" component={AcceptInvite} layout="public" />
            <AppRoute exact path="/verify-email" component={VerifyEmail} layout="public" />

            <AppRoute exact path="/legal/privacy-policy/en" component={PrivacyPolicy} layout="public" />
            <AppRoute exact path="/legal/privacy-policy/ru" component={PrivacyPolicy} layout="public" />
            <AppRoute exact path="/legal/terms-of-use/en" component={TermsOfUse} layout="public" />

            <NotFoundPage />
          </Switch>
        </TeamProvider>
      </BrowserRouter>
    );
  }
}

export default Routes;
