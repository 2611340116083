import { ApolloError } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { getFromLocalStorage, removeFromLocalStorage } from '../lib/localStorage';
import { useAcceptUserInviteMutation, UserFragment } from '../types/graphql';
import { useOnMutationFinishNotifications } from './useOnMutationFinishNotifications';

function useAcceptUserInvite() {
  const operationName = 'accept_user_invite';
  const { fail, success } = useOnMutationFinishNotifications();

  const handleSuccess = useCallback(() => {
    success(operationName);
    removeFromLocalStorage('invite_key');
    window.location.reload()
  }, [success]);

  const handleFail = useCallback(
    (error: ApolloError) => {
      fail(error.message, true);
      removeFromLocalStorage('invite_key');
    },
    [fail]
  );

  const [acceptUserInvite, acceptUserInviteResult] = useAcceptUserInviteMutation({
    onCompleted: handleSuccess,
    onError: handleFail,
  });

  const handleAcceptUserInvite = useCallback(
    (profile?: UserFragment) => {
      const key = getFromLocalStorage('invite_key');
      if (profile && key) {
        acceptUserInvite({ variables: { key } });
      }
    },
    [acceptUserInvite]
  );

  const error = acceptUserInviteResult.error;
  const isLoading = acceptUserInviteResult.loading;
  const ctx = useMemo(
    () => ({ acceptUserInvite: handleAcceptUserInvite, error, isLoading }),
    [handleAcceptUserInvite, error, isLoading]
  );
  return ctx;
}

export default useAcceptUserInvite;
