import { createGlobalStyle } from 'styled-components';
import { GET_COLOR_PRIMARY_1, GET_COLOR_SECONDARY_1 } from '../../lib/themeGetters';

export const BootstrapStyles = createGlobalStyle`
  :root {
    --blue: ${GET_COLOR_PRIMARY_1};
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: ${GET_COLOR_SECONDARY_1};
    --gray-dark: #343a40;
    --primary: ${GET_COLOR_PRIMARY_1};
    --secondary: ${GET_COLOR_SECONDARY_1};
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
`;
