import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { configureApollo } from '../../lib/configureApollo';
import I18nProvider from '../I18nProvider/I18nProvider';
import ThemeProvider from '../ThemeProvider/ThemeProvider';
import Routes from '../Routes/Routes';
import InitializationLoader from '../InitializationLoader/InitializationLoader';
import AuthProvider from '../AuthProvider/AuthProvider';

import LayoutProvider from '../LayoutProvider/LayoutProvider';
import NotificationProvider from '../NotificationProvider/NotificationProvider';

const client = configureApollo();

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <NotificationProvider>
          <I18nProvider>
            <AuthProvider>
              <LayoutProvider>
                <InitializationLoader>
                  <Routes />
                </InitializationLoader>
              </LayoutProvider>
            </AuthProvider>
          </I18nProvider>
        </NotificationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default React.memo(App);
