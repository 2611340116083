import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  getFromLocalStorage,
  LocalStorageTimerMap,
  removeFromLocalStorage,
  writeToLocalStorage,
} from '../lib/localStorage';

export interface IEntityTimerResult {
  rehydrateCalled: boolean;
  runTimer: () => void;
  rehydrateTimers: () => Promise<void>;
  clearTimer: () => void;
  time: number | null;
}

function useEntityTimer(storageKey: keyof LocalStorageTimerMap, reserveTime: number, notifyOnExpire?: () => void) {
  const [rehydrateCalled, setRehydrateCalled] = useState(false);
  const timerRef = useRef<{ timerId: NodeJS.Timeout | null }>({ timerId: null }).current;
  const [time, setTime] = useState<number | null>(null);

  const getTimerFromStorage = useCallback(() => {
    const storedTimers = getFromLocalStorage(storageKey);
    return storedTimers;
  }, [storageKey]);

  const removeTimerFromStorage = useCallback(() => {
    removeFromLocalStorage(storageKey);
  }, [storageKey]);

  const tickTimer = useCallback((time: number) => {
    setTime(time);
  }, []);

  const stopTimer = useCallback(() => {
    if (!timerRef.timerId) {
      return;
    }
    clearInterval(timerRef.timerId);
  }, [timerRef.timerId]);

  const resetTime = useCallback(() => {
    setTime(null);
  }, []);

  const notify = useRef({ ref: notifyOnExpire });

  useEffect(() => {
    notify.current.ref = notifyOnExpire;
  }, [notifyOnExpire]);

  const runTimer = useCallback(
    (endTime: number) => {
      let time = endTime;
      tickTimer(time);
      timerRef.timerId = setInterval(() => {
        if (time <= 1) {
          tickTimer(0);

          notify.current.ref?.();
          stopTimer();
          removeTimerFromStorage();
          return;
        }

        time -= 1;
        tickTimer(time);
      }, 1000);
    },
    [stopTimer, timerRef, tickTimer, removeTimerFromStorage]
  );

  const writeTimerToStorage = useCallback(
    (time: number) => {
      return writeToLocalStorage(storageKey, JSON.stringify(time));
    },
    [storageKey]
  );

  const addEntityTimer = useCallback(() => {
    if (timerRef.timerId) {
      stopTimer();
      resetTime();
    }
    // from ms to s
    const endTime = Date.now() + reserveTime * 1000;
    writeTimerToStorage(endTime);
    runTimer(reserveTime);
  }, [reserveTime, resetTime, runTimer, stopTimer, timerRef.timerId, writeTimerToStorage]);

  const rehydrateTimer = useCallback(
    (item: number) => {
      const endTime = item;
      const timeLeft = Math.round((endTime - Date.now()) / 1000);
      if (timeLeft > 0) {
        runTimer(timeLeft);
      }
    },
    [runTimer]
  );

  const clearTimer = useCallback(() => {
    if (timerRef.timerId) {
      stopTimer();
      resetTime();
    }
  }, [resetTime, stopTimer, timerRef.timerId]);

  const isCorrectDate = (time: number) => time - Date.now() > 0;

  // call only once
  const rehydrateTimers = useCallback(async () => {
    setRehydrateCalled(true);
    const storedTimer = getTimerFromStorage();
    // nothing to rehydrate
    if (!storedTimer && typeof storedTimer !== 'number') {
      return;
    }

    const isCorrectTime = isCorrectDate(storedTimer);

    // time is already expired
    if (!isCorrectTime) {
      removeTimerFromStorage();
      return;
    }

    rehydrateTimer(storedTimer);
  }, [getTimerFromStorage, rehydrateTimer, removeTimerFromStorage]);

  useEffect(() => {
    if (rehydrateCalled) {
      return;
    }
    rehydrateTimers();
  }, [rehydrateCalled, rehydrateTimers]);

  const ctx = useMemo(
    () => ({ rehydrateCalled, runTimer: addEntityTimer, rehydrateTimers, clearTimer, time }),
    [rehydrateCalled, addEntityTimer, rehydrateTimers, clearTimer, time]
  );

  return ctx;
}

export default useEntityTimer;
