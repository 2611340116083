import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { GET_COLOR_CONTRAST_1, GET_COLOR_DANGER_1, GET_COLOR_SUCCESS_1 } from '../../lib/themeGetters';

interface IProps {}

const StyledContainer = styled(ToastContainer)`
  .Toastify__toast {
    color: ${GET_COLOR_CONTRAST_1};

    &--error {
      color: white;
      background-color: ${GET_COLOR_DANGER_1};
    }
    &--success {
      background-color: ${GET_COLOR_SUCCESS_1};
    }
  }
`;

const NotificationProvider: React.FC<IProps> = props => {
  return (
    <>
      <StyledContainer />
      {props.children}
    </>
  );
};

export default NotificationProvider;
