import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TeamContext } from '../../contexts/contexts';
import { GET_COLOR_CONTRAST_1, GET_COLOR_SECONDARY_1 } from '../../lib/themeGetters';
import { AuthContext } from '../AuthProvider/AuthProvider';
import HeaderUserDropdownMenu from '../HeaderUserDropdownMenu/HeaderUserDropdownMenu';
import Popper from '../Popper/Popper';

const ToggleContainer = styled.div`
  cursor: pointer;
  color: ${GET_COLOR_SECONDARY_1};
`;

const UserName = styled.span`
  color: ${GET_COLOR_SECONDARY_1};
  text-align: end;
`;

const Caret = styled.span`
  width: 1px;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
`;

const Role = styled.span`
  color: ${GET_COLOR_CONTRAST_1};
  opacity: 0.38;
  text-align: end;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 190px;
`;

interface IProps {}

const HeaderUser: React.FC<IProps> = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const authContext = useContext(AuthContext);

  const profile = authContext.profile;
  const { teamMember } = useContext(TeamContext);

  const role = teamMember?.role;

  const toggle = useCallback(() => setDropdownOpen(prevState => !prevState), []);

  const toggleButtonContent = useMemo(
    () =>
      profile ? (
        <ToggleContainer className="d-flex flex-row cursor-pointer">
          <div className="d-flex flex-column justify-content-end">
            <UserName>{`${profile.firstName} ${profile.lastName}`}</UserName>
            {role && <Role>{role.name}</Role>}
          </div>
          <div>
            <Caret />
          </div>
        </ToggleContainer>
      ) : undefined,
    [profile, role]
  );

  const content = useMemo(
    () => (
      <Container>
        <HeaderUserDropdownMenu />
      </Container>
    ),
    []
  );

  return (
    <Popper
      contentStyle="border-radius:16px;"
      content={content}
      buttonContent={toggleButtonContent}
      isOpen={dropdownOpen}
      onToggle={toggle}
    />
  );
};

export default React.memo(HeaderUser);
