import { DefaultTheme } from 'styled-components';
import { COMMON_THEME_FIELDS, RANDOM_COLORS } from './assets/common';

export const THEME_WHITE: DefaultTheme = {
  name: 'white',
  randomColors: RANDOM_COLORS,
  colors: {
    main1: '#fff',
    main2: '#EFEFEF',
    main3: '#F5F5F7',

    contrast1: '#061043',
    contrast2: '#BFBFBF',
    contrast3: '#6e7486',
    contrast4: '#CFD2DC',

    contrastVibrant1: '#ffffff',
    contrastVibrant2: '#ffffff',
    contrastVibrant3: '#ffffff',

    info1: '#77A2E4',
    info2: '#71C3E0',
    info3: '#6BD9DA',

    danger1: '#DB0815',
    danger2: '#E65F5E',
    danger3: '#D91521',
    danger4: '#FF003D',

    success1: '#7AE994',
    success2: '#7AE994',
    success3: '#7AE994',

    warning1: '#EC9460',
    warning2: '#EC9460',
    warning3: '#EC9460',

    primary1: '#FE0000',
    primary2: '#FFE0E0',
    primary3: '#ffe3e3',
    secondary1: '#3A87FD',
    secondary2: '#E7F0FE',
    secondary3: '#3A87FD',

    parkingWidgetBackground: '#287CE2',
  },
  shadows: {
    shadow1: `0px 2px 3px 0 rgba(0, 0, 0, 0.1)`,
    shadow2: `0px 4px 5px 0 rgba(0, 0, 0, 0.1)`,
    shadow3: `rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;`,
    danger1: `1px 3px 8px 2px rgb(243 32 19 / 50%)`,
  },
  separator: {
    colors: {
      main1: '#1C3483',
    },
  },
  background: {
    parkingTicket: 'rgba(255,255,255,0.29)',
    matchTicket: 'rgba(255,255,255,0.33)',
  },
  gradients: {},
  lodgeColor: {
    active: '#90192B',
    sectorSale: '#84744A',
    inactive: '#DCDCDC',
  },
  ...COMMON_THEME_FIELDS,
};
